.destaque-produtos {
		
		padding-bottom: 160px;
		margin-top: 40px;
		
			@include mq(md) {
				padding-bottom: 60px;
			}

		.wrapper {
			position: relative;

			&:before {
				@include mq(md) {
				content: '';
				width: 340px;
				height: 340px;
				display: block;
				position: absolute;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='340' height='340' viewBox='0 0 340 340'%3E%3Cg id='Elipse_68' data-name='Elipse 68' fill='none' stroke='%233bbfc0' stroke-width='10'%3E%3Ccircle cx='170' cy='170' r='170' stroke='none'/%3E%3Ccircle cx='170' cy='170' r='165' fill='none'/%3E%3C/g%3E%3C/svg%3E%0A");
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				left: -240px;
				top: 50%;
				margin-top: -170px;
			}
			}
		}
	
		&__logo-selo { text-align: center; padding-bottom: 20px;}
		
		&-el {
			position: absolute;
			left: -215px;
			top: 240px;
		}
		
		&__swiper { 
			overflow: hidden; 
		}
		
		.swiper-slide {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			opacity: 0;
			transition: .2s;
		
			@include mq(md) {
				flex-direction: row;
			}

			&-active {
				transition: .2s;
				opacity: 1;
			}
		}
		
		&__img {
			padding-right: 0px;
			min-height: auto;
		}
		
		&__txt {
			padding-top: 30px;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			
			h2 { font-size: em(16); text-transform: uppercase;}
			h3 { 
				font-size: em(30); 
				color: var(--light-blue); 
				font-weight: 700;
			}
			p {
				padding: 0 20px; text-align: center; 
				// height: 70px;
			}
		}
		
		@include mq(md) {
			&__img {
				width: 580px;
				padding-right: 20px;
				min-height: 430px;
			}
			
			&__txt {
				padding-top: 40px;
				width: 480px;
				
				h2 {}
				h3 { font-size: em(55);}
				p {padding: 0 20px; text-align: center;}
			}
		}
		
		.swiper-group {
			position: absolute;
			bottom: -90px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			grid-gap: 30px;
			width: 100%;
    		justify-content: center;
    		left: 0;
    		right: -15px;
			
			@include mq(md) {
				// right: 60px;
				bottom: 0px;
				right: 15px;
        		width: 480px;
        		left: auto;
        		// justify-content: center;
			}
			
			.swiper-button-next, .swiper-button-prev, .swiper-pagination { position: relative; margin: 0; }
			
			.swiper-button-next, .swiper-button-prev {
				// margin: 0 30px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cg id='Componente_80_31' data-name='Componente 80 – 31' transform='translate(50 50) rotate(180)'%3E%3Ccircle id='Elipse_181' data-name='Elipse 181' cx='25' cy='25' r='25' fill='%23134a9c' opacity='0.2'/%3E%3Cg id='Icon' transform='translate(20 19)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cline id='c018084a-acf8-426b-9467-11b92e471d11' x1='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3Cpath id='_5f56acfb-6dcf-48d2-bce4-faecc2862052' data-name='5f56acfb-6dcf-48d2-bce4-faecc2862052' d='M10.286,16.286l-6-6,6-6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				flex: 0 0 50px;
			}
			
			.swiper-button-prev {
				transform: scaleX(-1);
			}
			
			.swiper-pagination {
				background: white;
				
				.swiper-pagination-bullet {
				  width: 20px;
				  height: 10px;
				  border-radius: 10px;
				  background: #CCD8E8;
				  opacity: 1;
				}
				
				.swiper-pagination-bullet-active {
				  opacity: 1;
				  width: 50px;
				  background: var(--dark-blue);
				}
			}
		}
	}