.busca-vazia-heading {
	padding-bottom: 20px;
	
	&__icon {
		text-align: center;
		padding-top: 20px;
	}

	@include mq(md) { 	
		background-image: 	url("../../images/el-heading.png");
		background-position: 0 -150px;
		background-repeat: no-repeat;
    }

	.heading__grid {
        grid-template-columns: none;
    }
}

.busca-heading {

	@include mq(md) { 	
		background-image: 	url("../../images/el-heading-m.png"),
							url("../../images/el-heading.png");
		background-position: calc(100% - 50px) calc(100% + 200px), 0 -150px;
    }

	.heading__grid {
        grid-template-columns: none;
    }
	
	p { color: var(--white); }
	
}
