.contato-heading {
    padding-bottom: 50px;

	@include mq(md) { 	
		padding-bottom: 80px;
		background-image: 	url("../../images/el-heading.png");		
		background-position: 	0 -150px;
    }
		
	p { color: white; }

	.heading__grid {
        grid-template-columns: none;
		grid-gap: 0;
    }
	
}