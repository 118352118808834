h1 {
	font-size: em(30);
	line-height: em(14);
	font-weight: 700;
	margin-top: 10px;
	font-family: "PeachiBold", sans-serif;

	@include mq(md) {
		font-size: em(40);
		line-height: em(13);
	}
}

h2 {
	font-size: em(25);
	line-height: em(12);
	font-weight: 500;
	margin-top: 10px;
	font-family: "PeachiBold", sans-serif;
	font-style: normal;

	strong {
		font-weight: 700;
	}

	@include mq(md) {
		font-size: em(30);
		line-height: em(14);
	}
}

h3 {
	font-size: em(25);
	font-weight: 400;
	line-height: em(12);
	margin: 10px 0;
	font-family: "PeachiRegular", sans-serif;

	strong {
		font-weight: 700;
		font-family: "PeachiBold", sans-serif;
	}

	@include mq(md) {
		font-size: em(20);
	}
}

h4 {
	font-size: em(16);
	font-weight: 700;
	line-height: em(12);
	margin-top: 10px;
	font-family: "PeachiBold", sans-serif;
}

h5 {
	font-size: em(14);
	font-weight: 700;
	line-height: em(12);
	margin-top: 10px;
	border: 1px solid var(--text-blue);
	border-radius: 50px;
	display: inline-block;
	padding: 8px 20px;
	margin-bottom: 40px;

	&.has-text-align-right {
		float: right;
		text-align: right;
		&:after {
			float: none;
			height: 0;
			content: "";
			display: block;
		}
	}
}

h6 {
	font-size: em(16);
	font-weight: 400;
	line-height: em(15);
	margin: 10px 0;
	text-transform: uppercase;

	@include mq(md) {
		font-size: em(20);
	}
}

p {
	margin: 10px 0;
}

p,
li,
table {
	font-size: em(16);
	line-height: em(14);

	li {
		font-size: 1em;
	}
}

.table-bordered thead td,
.table-bordered thead th {
	border-bottom-width: 2px;
}
.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #dee2e6;
	background: #dee2e6;
}
.table-bordered td,
.table-bordered th {
	border: 1px solid #dee2e6;
}
.table td,
.table th {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}

table p {
	font-size: 1em;
}

hr.wp-block-separator {
	border: 0;
	border-top: 1px solid #3444a350;
}

.wp-block-button__link {
	background-color: transparent !important;
	color: #fff;
	padding: 18px 30px 18px 30px;
	font-size: em(16);
	font-weight: 500;
	margin-top: 40px;
	margin-bottom: 25px;
	display: flex !important;
	align-items: center;
	transition: 0.2s;
	grid-gap: 50px;
	justify-content: center;

	@include mq(md) {
		background-image: none;
	}

	// &:hover {
	//   background-image: linear-gradient(180deg, var(--blue04) 0%, var(--light-blue) 100%);
	//   transition: .2s;
	// }
}

.btn {
	color: var(--white);
	background-color: var(--dark-blue);
	padding: 18px 50px;
	font-size: em(16);
	font-weight: 500;
	margin-top: 20px;
	display: flex;
	align-items: center;
	transition: 0.2s;
	grid-gap: 50px;
	border-radius: 50px;
	text-decoration: none;
	cursor: pointer;
	text-align: center;

	&:hover {
		background-color: var(--text-blue);
		transition: 0.2s;
	}
}

.btn-shadow {
	color: var(--white);
	background-color: var(--dark-blue);
	padding: 18px 30px 18px 30px;
	font-size: em(16);
	font-weight: 500;
	margin-top: 20px;
	display: flex;
	align-items: center;
	transition: 0.2s;
	grid-gap: 50px;
	border-radius: 50px;
	text-decoration: none;
	cursor: pointer;
	-webkit-box-shadow: 0px 10px 21px -8px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 10px 21px -8px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 10px 21px -8px rgba(0, 0, 0, 0.5);

	&:hover {
		background-color: var(--text-blue);
		transition: 0.2s;
	}
}

.btn-icone {
	color: var(--white);
	background-color: var(--text-blue);
	padding: 18px 30px 18px 30px;
	font-size: em(16);
	font-weight: 500;
	margin-top: 20px;
	display: flex;
	align-items: center;
	transition: 0.2s;
	grid-gap: 50px;
	border-radius: 50px;
	text-decoration: none;
	cursor: pointer;
	-webkit-box-shadow: 0px 10px 21px -8px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 10px 21px -8px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 10px 21px -8px rgba(0, 0, 0, 0.5);

	&:hover {
		background-color: var(--dark-blue);
		transition: 0.2s;
	}
}

.btn-border {
	color: var(--white);
	border: 1px solid #4d687f;
	padding: 13px 22px 13px 22px;
	font-size: em(16);
	font-weight: 500;
	margin-top: 20px;
	display: flex;
	align-items: center;
	transition: 0.2s;
	grid-gap: 50px;
	border-radius: 50px;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		background-color: var(--dark-blue);
		transition: 0.2s;
		color: var(--white);
	}
}

.wp-embed-aspect-16-9 {
	aspect-ratio: 16 / 9;
	display: flex;

	iframe {
		height: 100%;
		aspect-ratio: 16 / 9;
		width: 100%;
	}

	.wp-block-embed__wrapper {
		width: 100%;
	}
}

.bt-voltar {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	color: #000;
	position: absolute;
	top: 85px;
	z-index: 1;
	background-color: transparent;
	border: 0;
	margin-left: 0;
	cursor: pointer;
	font-size: 0;

	@include mq(md) {
		margin-left: 15px;
		font-size: em(14);
		top: 125px;
	}

	&:before {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg id='Icon' transform='translate(0.5 0.5)'%3E%3Crect id='Area' width='16' height='16' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Ccircle id='fa5f5fe8-fd0f-4d90-a39e-de494ab8b9d1' cx='8' cy='8' r='8' transform='translate(-1.29 -2.499)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='_8beb2922-058e-4960-a691-69541ab0a224' data-name='8beb2922-058e-4960-a691-69541ab0a224' d='M9.6,6.4,6.4,9.6l3.2,3.2' transform='translate(-2.89 -4.099)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='_7a4f4de6-09d0-4aac-9f6f-6400826a1a59' data-name='7a4f4de6-09d0-4aac-9f6f-6400826a1a59' x1='6.4' transform='translate(3.51 5.501)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
		width: 16px;
		height: 16px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		content: "";
	}
}

.bt-inscreva {
	background: linear-gradient(
		120deg,
		var(--dark-orange) 0%,
		var(--light-orange) 100%
	);
	display: flex;
	height: 60px;
	padding: 0 45px;
	border-radius: 60px;
	color: #fff;
	text-decoration: none;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	font-size: em(14);

	&:hover {
		background: linear-gradient(120deg, #c17f34, #ffcd98);
		transition: 0.2s;
	}
}

body:has(.cabecalho-gradiente) {
	.bt-voltar {
		color: #fff;

		&:before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg id='Icon' transform='translate(0.5 0.5)'%3E%3Crect id='Area' width='16' height='16' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Ccircle id='fa5f5fe8-fd0f-4d90-a39e-de494ab8b9d1' cx='8' cy='8' r='8' transform='translate(-1.29 -2.499)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='_8beb2922-058e-4960-a691-69541ab0a224' data-name='8beb2922-058e-4960-a691-69541ab0a224' d='M9.6,6.4,6.4,9.6l3.2,3.2' transform='translate(-2.89 -4.099)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='_7a4f4de6-09d0-4aac-9f6f-6400826a1a59' data-name='7a4f4de6-09d0-4aac-9f6f-6400826a1a59' x1='6.4' transform='translate(3.51 5.501)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
		}
	}
}

.wp-block-image {
	img {
		border-radius: 25px;
		overflow: hidden;
	}

	figcaption {
		font-size: em(12);
		color: var(--text-blue);
		display: table !important;
		margin: 0 auto;
		padding: 5px 20px;
		background: #fff;
		border-radius: 5px;
		margin-top: -10px !important;
		position: relative;
		-webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.06);
		-moz-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.06);
		box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.06);
	}
}
