.produto-gallery {
	width: auto;
	margin-top: -250px;

	@include mq(md) {
		margin-top: -250px;
		width: 450px;
	}

	.swiper {
		overflow: hidden;
		position: relative;
		padding-bottom: 50px;
		margin-bottom: 30px;
	}
	.swiper-slide {
		align-items: center;
		justify-content: center;
		display: flex;
		height: 350px;

		@include mq(md) {
			height: 430px;
		}

		img {
		    height: 100%;
		    width: 100%;
    		object-fit: contain;
		}
	}
	.swiper-pagination {
		background: transparent;
		display: flex;
    	justify-content: center;
    	width: 100%;
    	bottom: 0px;
		
		.swiper-pagination-bullet {
			width: 20px;
			height: 10px;
			border-radius: 10px;
			background: var(--cor-categoria);
			opacity: .5;
		}
		
		.swiper-pagination-bullet-active {
			opacity: 1;
			width: 50px;
			background: var(--cor-categoria);
		}
	}
}