.card-produto {
	width: 225px;
	&__img {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 200px;
		height: 290px;
		transition: .2s;
		margin: 0;

		&:after {
			position: absolute;
			content: '';
			width: 100px;
			height: 100px;
			opacity: 0;
			border-radius: 50%;
			border: 1px solid;
			border-color: var(--cor-categoria);
			transition: .2s;
		}

		img {
			position: relative;
			z-index: 1;
			width: 90%;
			transition: .2s;
			object-fit: contain;
    		height: 90%;
		}

		&:hover {
			&:after {
				width: 212px;
				height: 212px;
				opacity: 1;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	a {
		text-decoration: none;
		display: flex;
    	flex-direction: column;
    	align-items: center;
	}

	h3 {
		font-size: em(18);
		color: var(--dark-blue);
		text-align: center;
		margin: 0;
		font-weight: 700;
	}
}