.perguntas-receitas-heading {
	mask-image: url(../../images/curva-desk.svg);
	background-color: var(--cor-categoria);
	background-position: calc(100% - 50px) calc(100% + 200px);
}

.page-template-busca-receita {
	.footer {
		display: none;
	}
}
