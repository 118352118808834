body{
	overflow-x: visible;
}
.onde-heading {
	h1 {
		text-align: center;

		@include mq(md) {
			text-align: left;
		}
	}
}