.politicas-content {
	padding-top: 70px;
	padding-bottom: 100px;

		@include mq(md) { 
			padding-top: 100px;
			background-image: 	url("../../images/el-politicas.svg");
			background-position: calc(100% + 160px) 1800px;
			background-repeat: no-repeat;
    }
	
	&__grid {

		@include mq(md) { 
			display: flex;
			justify-content: space-between;
		}
		
	}
	

	&__links {
		margin-bottom: 50px;

		@include mq(md) { 
			margin-bottom: 0;
		}
	}
	&__menu {
		width: 100vw;
		overflow-x: scroll;
		margin: 0 -30px;
		padding: 30px;
		display: flex;
		
		@include mq(md) { 
			width: 320px;
			overflow-x: auto;
			margin: 0;
			display: block;
		}

		a {
						padding: 8px 20px;
						display: block;
						border: 1px solid var(--dark-blue);
						border-radius: 30px;
						margin-right: 10px;
						text-decoration: none;
						color: var(--dark-blue);
						font-size: em(16);
						flex: 0 0 auto;
    					max-width: 80vw;
							
						&:hover, &:active, &.ativo {
							color: var(--white); 
							background-color: var(--dark-blue);
						}

						@include mq(md) { 
							font-family: "PeachiRegular", sans-serif;
						font-style: normal;
						// text-transform: uppercase;
						text-decoration: none;
						padding: 20px 0;
						border: 0;
						border-radius: 0;
						background-repeat: repeat-x;
						background-position: left bottom;
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='502' height='3' viewBox='0 0 502 3'%3E%3Cline id='Linha_50' data-name='Linha 50' x1='502.5' transform='translate(1.5 1.5)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-width='3' stroke-dasharray='0 10'/%3E%3C/svg%3E%0A");
						max-width: auto;

						&:hover, &:active, &.ativo {
							background-color: transparent; 
							color: var(--dark-blue); 
							font-family: "PeachiRegular", sans-serif;
						}
						}
					}
		
		ul {
			width: 2280px;
			margin-bottom: 30px;
		
			@include mq(md) { 
				width: auto;
			}
			
			li {
				display: inline-block;

					a {
						padding: 8px 20px;
						display: block;
						border: 1px solid var(--dark-blue);
						border-radius: 30px;
						margin-right: 10px;
						text-decoration: none;
						color: var(--dark-blue);
							
						&:hover, &:active, &.ativo {
							color: var(--white); 
							background-color: var(--dark-blue);
						}
					}
			
				@include mq(md) { 
				
					display: block;
				
					a {
						
						font-family: "PeachiRegular", sans-serif;
						font-style: normal;
						text-transform: uppercase;
						text-decoration: none;
						padding: 20px 0;
						border: 0;
						border-radius: 0;
						background-repeat: repeat-x;
						background-position: left bottom;
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='502' height='3' viewBox='0 0 502 3'%3E%3Cline id='Linha_50' data-name='Linha 50' x1='502.5' transform='translate(1.5 1.5)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-width='3' stroke-dasharray='0 10'/%3E%3C/svg%3E%0A");
						
						&:hover, &:active, &.ativo {
							background-color: transparent; 
							color: var(--dark-blue); 
							font-family: "PeachiBold", sans-serif;
						}
					}
				}
			}
		}
	}
	
	&__conteudo {
		@include mq(md) { 
			width: 630px;
		}
		
		h2 { 
			color: var(--dark-blue); 
			padding-top: 30px;
			margin-top: -100px;
			&:first-child { padding-top: 0; }

			&:before {
			    content: '';
    height: 100px;
    display: block;
    
}
		}
		
	}
}