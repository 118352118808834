.hamburger {
  padding: 15px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  height: 3px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;

}

.hamburger.is--active .hamburger-inner,
.hamburger.is--active .hamburger-inner::before,
.hamburger.is--active .hamburger-inner::after {
  background-color: var(--text-blue);
}

.hamburger-box {
  width: 20px;
  height: 20px;
  // display: inline-block;
  position: relative;
  display: flex;
  justify-content: flex-end;

}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 20px;
}



.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
  width: 20px;
  right: 0;
}

.hamburger-inner::after {
  // width: 18px;
  bottom: -10px;
  width: 20px;
  right: 0;
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 16px;
}

.hamburger.is--active .hamburger-inner::after {
  width: 36px;
}

.hamburger.is--active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
  width: 20px;
}

.hamburger.is--active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
  width: 20px;
}

.hamburger.is--active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
  width: 20px;
  top: 20px;
}