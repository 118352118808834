.onde-content {
	position: relative;
	height: 880px;
	padding-top: 50px;

	@include mq(md) {
		padding-top: 0;
	}

	&__wrapper {
		display: flex;
		flex-direction: column-reverse;

		@include mq(md) {
			flex-direction: row;
		}
	}

	&__map {
		position: relative;
		height: 570px;
		
		width: 100vw;
		background: gray;
		margin-left: -30px;
		margin-right: -30px;

		@include mq(md) {
			position: absolute;
			top: 0;
		left: 0;
		right: 0;
		height: 880px;
		margin: 0;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;
		align-items: center;

		@include mq(md) {
			grid-template-columns: 420px 1fr;
		}
	}
}