.receita-avaliacoes {
	&:before {
		content: '';
		display: block;
		margin: 50px 0;
			border: none;
			height: 20px;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='505.5' height='3' viewBox='0 0 505.5 3'%3E%3Cline id='Linha_50' data-name='Linha 50' x1='502.5' transform='translate(1.5 1.5)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-width='3' stroke-dasharray='0 10'/%3E%3C/svg%3E%0A");

	}


	&__ttl {
		display: flex;
		grid-gap: 30px;
	}

	&__header {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}

	.swiper {
		overflow: hidden;
	}

	h4 {
		margin: 0;
	}

	&__nav {
		position: relative;
		width: 50px;

		.swiper-button-prev {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cg id='Icon' transform='translate(12) rotate(90)'%3E%3Crect id='Area' width='12' height='12' fill='none' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='be395840-9477-496e-9761-88ee6aa17a40' d='M0,0H12V12H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_96098602-5feb-46a6-b5ac-2fe8a5269d34' data-name='96098602-5feb-46a6-b5ac-2fe8a5269d34' d='M3,4.5l3,3,3-3' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
			width: 21px;
			height: 21px;
			border: 1px solid var(--dark-blue);
			background-size: 12px 12px;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 50%;
			left: 0;
		}

		.swiper-button-next {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cg id='Icon' transform='translate(0 12) rotate(-90)'%3E%3Crect id='Area' width='12' height='12' fill='none' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='be395840-9477-496e-9761-88ee6aa17a40' d='M0,0H12V12H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_96098602-5feb-46a6-b5ac-2fe8a5269d34' data-name='96098602-5feb-46a6-b5ac-2fe8a5269d34' d='M3,4.5l3,3,3-3' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
			width: 21px;
			height: 21px;
			border: 1px solid var(--dark-blue);
			background-size: 12px 12px;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 50%;
			right: 0;
		}
	}

	&__btn {
		display: flex;
		background: transparent;
		border: 0;
		color: var(--dark-blue);
		grid-gap: 10px;
		align-items: center;
		cursor: pointer;
		margin-top: 30px;

		&:before {
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.25' height='20.409' viewBox='0 0 20.25 20.409'%3E%3Cpath id='Caminho_13450' data-name='Caminho 13450' d='M16.862,4.487,18.549,2.8A1.875,1.875,0,0,1,21.2,5.451L10.582,16.07a4.5,4.5,0,0,1-1.9,1.13L6,18l.8-2.685a4.5,4.5,0,0,1,1.13-1.9l8.932-8.931Zm0,0L19.5,7.125M18,14v4.75A2.25,2.25,0,0,1,15.75,21H5.25A2.25,2.25,0,0,1,3,18.75V8.25A2.25,2.25,0,0,1,5.25,6H10' transform='translate(-2.25 -1.341)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
			width: 19px;
			height: 19px;
			background-size: 19px;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}
	}

	&__form {
		.comment-respond {
		margin-top: 50px;
		display: none;

		&.is--active {
			display: block;
		}
		form {
		display: flex;
		flex-direction: column;

		textarea {
			border-radius: 30px;
			color: var(--dark-blue);
		}

		input[type="submit"] {
			color: var(--white);
    		background-color: var(--dark-blue);
    		grid-gap: 50px;
		    cursor: pointer;
		    text-align: center;
		    border-radius: 50px;
		    align-items: center;
		    margin-top: 20px;
		    padding: 18px 30px;
		    font-weight: 500;
		    text-decoration: none;
		    transition: all .2s;
		    display: table;
		}
	}
	}
}

&__aprovacao {
	padding-top: 40px;
}
}

#stars-rating-review .br-widget a.br-selected:after {
    color: #F1DC53 !important;
}

.comment-form-cookies-consent {
	display: flex;
    align-items: center;

    input {
    	width: 50px;
    }
}