.header-busca {
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border-radius: 60px;
  padding: 5px;
  grid-gap: 5px;
  position: absolute;
  transition: .2s;
  right: 20px;
  z-index: 50;

  @include mq(md) {
    right: 0px;
  }

  #s {
    width: 100%;
    display: none;
    height: 50px;
    border-radius: 60px;
    padding: 0 15px;
    color: var(--dark-blue);
    font-size: em(16);
  }
  &__btn {
      border: 0;
      background-color: transparent;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      cursor: pointer;
      background-image: url('data:image/svg+xml,<svg id="Icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><rect id="Area" width="20" height="20" fill="%23fcfcfc" opacity="0"/><g id="Icon-2" data-name="Icon" transform="translate(1.29 2.499)"><path id="bf85fc15-e260-4dc6-ab9f-b3885d5dddf6" d="M0,0H20V20H0Z" transform="translate(-1.29 -2.499)" fill="none"/><path id="_929e80f7-f3f8-48f4-83e4-d1ff3a788f4d" data-name="929e80f7-f3f8-48f4-83e4-d1ff3a788f4d" d="M14.167,8.333A5.833,5.833,0,1,1,8.333,2.5,5.834,5.834,0,0,1,14.167,8.333Z" transform="translate(-1.29 -2.499)" fill="none" stroke="%23fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.667"/><path id="b92afa92-b399-4075-9b76-418d49c58009" d="M17.5,17.5l-5-5" transform="translate(-1.29 -2.499)" fill="none" stroke="%23fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.667"/></g></svg>');
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      right: 5px;
          z-index: 1;

      @include mq(md) {
        background-color: #F3F5F730;
      }
    }
  form {
    width: 100%;
    div {
      width: 100%;
      display: flex;
    }
    
  }

  input[type=submit] {
    border: 0;
      background-color: transparent;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='bf85fc15-e260-4dc6-ab9f-b3885d5dddf6' d='M0,0H20V20H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_929e80f7-f3f8-48f4-83e4-d1ff3a788f4d' data-name='929e80f7-f3f8-48f4-83e4-d1ff3a788f4d' d='M14.167,8.333A5.833,5.833,0,1,1,8.333,2.5,5.834,5.834,0,0,1,14.167,8.333Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='b92afa92-b399-4075-9b76-418d49c58009' d='M17.5,17.5l-5-5' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
      font-size: 0;
      z-index: 1;
      margin: 0;

      @include mq(md) {
        background-color: #F3F5F730;
      }
  }

  &.is--active {
    width: 285px;
    background-color: #fff;

    #s {
      display: block;
      border: 0;
      background-color: transparent;
    }

    input[type=submit] {
      z-index: 2;
    }
  }
}