.perguntas-receitas {
	&-content {
		background-color: var(--dark-blue);
		padding-top: 40px;
		padding-bottom: 80px;
		&.bg-resultado {
			background: var(--dark-blue)
				url(../../images/bg-resultado-single.png) no-repeat center 260px;
			background-size: contain;
			@include mq(md) {
				background: var(--dark-blue) url(../../images/bg-resultado2.png)
					no-repeat center 260px;
			}
			&-2 {
				.resultado1 {
					background: url(../../images/bg-resultado-single.png)
						no-repeat top;
					background-size: contain;
				}
				.resultado2 {
					background: url(../../images/bg-resultado-single-2.png)
						no-repeat top;
					background-size: contain;
				}
				@include mq(md) {
					background: var(--dark-blue)
						url(../../images/bg-resultado2.png) no-repeat center
						260px;
					.resultado1,
					.resultado2 {
						background: unset;
						background-size: unset;
					}
				}
			}
		}
		h2,
		h3,
		p {
			text-align: center;
			color: var(--white);
			font-family: "PeachiBold";
		}
		.perguntas-receitas-anim {
			margin-top: 30px;
			text-align: center;
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
			&.no-recipe {
				.clique {
					transform: translateY(-460px);
					@include mq(md) {
						transform: unset;
					}
				}
			}
			.clique {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 30px;
				@include mq(md) {
					flex-direction: row;
				}
				cursor: pointer;
				.text {
					padding-bottom: 50px;
				}
				&:hover {
					.text {
						a {
							text-decoration: underline;
						}
					}
					.estrelas,
					.estrelas2 {
						position: relative;
						&.step-6 {
							.estrela {
								position: absolute;
								transition: 0.2s;
								&:nth-of-type(1) {
									transform: translate(-35px, 15px);
								}
								&:nth-of-type(2) {
									transform: translate(40px, 20px);
								}
								&:nth-of-type(3) {
									transform: translate(5px, -30px);
								}
							}
						}
					}
				}
				.text {
					display: none;
					a {
						color: var(--white);
						font-size: 20px;
						font-family: "PeachiBold";
						@include mq(md) {
							font-size: 30px;
						}
					}
				}
				.estrelas,
				.estrelas2 {
					position: relative;
					display: flex;
					justify-content: center;
					text-align: center;
					width: 135px;
					height: 100px;
					align-items: center;
					transition: 0.2s;
					&.step-1 {
						.estrela {
							position: absolute;
							transition: 0.2s;
							&:nth-of-type(1) {
								transform: translate(5px, -30px);
								@include mq(md) {
								}
							}
							&:nth-of-type(2) {
								transform: translate(-35px, 15px);
								@include mq(md) {
								}
							}
							&:nth-of-type(3) {
								transform: translate(40px, 20px);
								@include mq(md) {
								}
							}
						}
					}
					&.step-2 {
						.estrela {
							position: absolute;
							transition: 0.2s;
							&:nth-of-type(1) {
								transform: translate(-35px, 15px);
								@include mq(md) {
									transform: translate(-35px, 15px);
								}
							}
							&:nth-of-type(2) {
								transform: translate(40px, 20px);
								@include mq(md) {
									transform: translate(40px, 20px);
								}
							}
							&:nth-of-type(3) {
								transform: translate(5px, -30px);
								@include mq(md) {
									transform: translate(5px, -30px);
								}
							}
						}
					}
					&.step-3 {
						.estrela {
							position: absolute;
							transition: 0.2s;
							&:nth-of-type(1) {
								transform: translate(40px, 20px);
								@include mq(md) {
									transform: translate(40px, 20px);
								}
							}
							&:nth-of-type(2) {
								transform: translate(5px, -30px);
								@include mq(md) {
									transform: translate(5px, -30px);
								}
							}
							&:nth-of-type(3) {
								transform: translate(-35px, 15px);
								@include mq(md) {
									transform: translate(-35px, 15px);
								}
							}
						}
					}
					&.step-4 {
						.estrela {
							position: absolute;
							transition: 0.2s;
							&:nth-of-type(1) {
								transform: translate(30px, -520px);
								@include mq(md) {
									transform: translate(30px, -120px);
								}
							}
							&:nth-of-type(2) {
								transform: translate(50px, -600px);
								@include mq(md) {
									transform: translate(50px, -200px);
								}
							}
							&:nth-of-type(3) {
								transform: translate(-60px, -560px);
								@include mq(md) {
									transform: translate(-60px, -160px);
								}
							}
						}
					}
					&.step-5 {
						.estrela {
							position: absolute;
							transition: 0.2s;
							&:nth-of-type(1) {
								transform: translate(50px, -600px);
								@include mq(md) {
									transform: translate(50px, -200px);
								}
							}
							&:nth-of-type(2) {
								transform: translate(-60px, -560px);
								@include mq(md) {
									transform: translate(-60px, -160px);
								}
							}
							&:nth-of-type(3) {
								transform: translate(30px, -520px);
								@include mq(md) {
									transform: translate(30px, -120px);
								}
							}
						}
					}
					&.step-6 {
						.estrela {
							position: absolute;
							transition: 0.2s;
							&:nth-of-type(1) {
								transform: translate(40px, 20px);
							}
							&:nth-of-type(2) {
								transform: translate(5px, -30px);
							}
							&:nth-of-type(3) {
								transform: translate(-35px, 15px);
							}
						}
					}
				}
			}
		}
	}
	&__swiper {
		.swiper-pagination {
			position: initial;
			background: none;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			text-align: initial;
			.swiper-pagination-bullet {
				background: none;
				width: unset;
				height: unset;
				flex: 1;
				transition: 0.2s;
				text-align: center;
				&:focus,
				&:focus-visible {
					border: none;
					outline: none;
				}
				&-active {
					transition: 0.2s;
				}
				@include mq(md) {
					text-align: left;
				}
				&:last-of-type {
					opacity: 1;
					color: #ffffff30;
					&:after {
						background: #ffffff30;
					}
					&:before {
						content: "";
						width: 0%;
						height: 3px;
						background: #ffffff;
						position: absolute;
						bottom: -10px;
						left: 0;
						transition: 6s;
						animation: linear;
					}
					&.animate {
						color: #ffffff;
						&:before {
							content: "";
							width: 100%;
							height: 3px;
							background: #ffffff;
							position: absolute;
							bottom: -10px;
							left: 0;
							transition: 6s;
							animation: linear;
						}
					}
				}
				color: var(--white);
				font-size: 12px;
				position: relative;
				font-family: "PeachiRegular";
				@include mq(md) {
					font-size: 16px;
				}
				&:after {
					content: "";
					width: 100%;
					height: 3px;
					background: var(--white);
					position: absolute;
					bottom: -10px;
					left: 0;
				}
			}
		}
		.swiper-slide {
			&:last-of-type {
				.slide-content {
					.step-1,
					.step-2 {
						margin-top: 40px;
						@include mq(md) {
							margin-top: 200px;
						}
					}
					.step-3 {
						margin-top: 40px;
						@include mq(md) {
							margin-top: 75px;
						}
						h2 {
							margin-bottom: 50px;
						}
						.resultados {
							@include mq(md) {
								display: flex;
							}
							&.single {
								.resultado.resultado1 {
									margin: 0 auto;
								}
							}
							.resultado {
								margin: 0 auto;
								flex: 1;
								@include mq(md) {
									max-width: 45%;
								}
								&.resultado1 {
									margin-left: 0;
									.resultado-img {
										&:before {
											// @include mq(md) {
											background-image: url(../../images/bg-resultado-destaque1-circle.svg);
											background-repeat: no-repeat;
											background-size: contain;
											@include mq(md) {
												background-repeat: initial;
												background-size: initial;
											}
											// }
										}
									}
								}
								&.resultado2 {
									margin-right: 0;
									.resultado-img {
										&:before {
											// @include mq(md) {
											background-image: url(../../images/bg-resultado-destaque2-circle.svg);
											background-repeat: no-repeat;
											background-size: contain;
											@include mq(md) {
												background-repeat: initial;
												background-size: initial;
											}
											// }
										}
									}
								}
								&-img {
									text-align: center;
									position: relative;
									width: 75vw;
									height: 75vw;
									margin: 0 auto;
									@include mq(md) {
										width: 477px;
										height: 480px;
									}
									&:hover {
										&:before {
											transform: rotate(-19deg);
											transition: 0.4s ease-in-out;
										}
									}
									img {
										border-radius: 50%;
										width: 69vw;
										height: 69vw;
										object-fit: cover;
										@include mq(md) {
											width: 446px;
											height: 446px;
										}
									}
									&:before {
										content: "";
										width: 100%;
										height: 100%;
										position: absolute;
										display: block;
										transition: 0.4s ease-in-out;
										top: -11px;
										@include mq(md) {
											top: -15px;
										}
									}
								}
								&-nome {
									margin-top: 20px;
									h3 {
										font-size: 30px;
										@include mq(md) {
											font-size: 40px;
										}
									}
								}
								&-descricao {
									p {
										font-family: "PeachiRegular";
									}
								}
								&-btn {
									border: 1px solid var(--white);
									font-size: 16px;
									font-family: "PeachiRegular";
									border-radius: 500px;
									justify-content: center;
									margin: 20px auto;
									@include mq(md) {
										font-size: 18px;
										width: 260px;
									}
								}
							}
						}
					}
				}
			}
			.slide-content {
				.perguntas {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 20px;
					margin-top: 40px;
					h2 {
						font-size: 25px;
						@include mq(md) {
							font-size: 50px;
						}
					}
					h3 {
						font-size: 20px;
						@include mq(md) {
							font-size: 25px;
						}
					}
				}
				.opcoes {
					margin-top: 40px;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					@include mq(md) {
						gap: 10px;
						flex-direction: row;
					}
					.btn {
						border: 1px solid var(--white);
						width: 60vw;
						font-size: 16px;
						font-family: "PeachiRegular";
						border-radius: 500px;
						padding: 18px 20px;
						justify-content: center;
						@include mq(md) {
							font-size: 18px;
						}
					}
				}
				.step-1,
				.step-2,
				.step-3 {
					opacity: 0;
					visibility: hidden;
					display: none;
					transition: 0.2s;
					&.active {
						opacity: 1;
						visibility: visible;
						display: block;
						transition: 0.2s;
					}
				}
			}
		}
	}
}
