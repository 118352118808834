.has--high-contrast {
  --orange: #000;
  --purple: #000;
  --lighter-gray: #fff;
  --light-gray: #fff;
  --dark-gray: #000;
  --darker-gray: #000;

  img {
    filter: grayscale(100%) contrast(150%);
  }

  video {
    filter: grayscale(100%) contrast(120%);
  }
  .destaque__wrapper {
    background: #000 !important;
  }
  .main-header {
    background: #000;
  }
  .nav-main #menu-menu-principal .current-menu-item a {
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
  }
}

.home.has--high-contrast {
  .main-header {
    background: transparent;
  }
}
