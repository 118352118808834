.sobre-content {
	padding-top: 70px;
	padding-bottom: 100px;

	@include mq(md) {
		padding-top: 100px;
		background-image: url("../../images/el-politicas.svg");
		background-position: calc(100% + 160px) 1800px;
		background-repeat: no-repeat;
	}

	&__marca {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding-top: 80px;

		@include mq(md) {
			justify-content: space-between;
		}

		h2 {
			text-align: center;
			flex-basis: 100%;
			padding-bottom: 70px;
			color: var(--dark-blue);
		}

		&-circle {
			text-align: center;
			width: 310px;
			position: relative;
			margin-bottom: 90px;

			&::after {
				content: "";
				top: -6px;
				left: -10px;
				width: 330px;
				height: 330px;
				position: absolute;
				background-image: url("../../images/sobre-nos-curva-azul.png");
				background-position: top left;
				background-repeat: no-repeat;
				background-size: contain;
			}

			.circle-image {
				border-radius: 350px;
				overflow: hidden;
				position: relative;
				width: 311px;
				height: 313px;
				backface-visibility: hidden;

				span {
					position: absolute;
					top: 40px;
					left: 0;
					z-index: 1;
					width: 100%;
					font-weight: 700;
					color: var(--white);
					font-family: "PeachiBold", sans-serif;
					font-style: normal;
					font-size: em(30);
				}

				img {
					width: 100%;
					max-width: 100%;
					width: 310px;
					height: 313px;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.circle-text {
				border-radius: 350px;
				background-color: var(--text-blue);
				width: 311px;
				height: 313px;
				z-index: 7;
				position: absolute;
				transition: all 0.5s linear;
				// opacity: 0;
				top: 0;
				backface-visibility: hidden;
				transform: rotateY(180deg);

				h3 {
					padding-top: 50px;
					padding-bottom: 10px;
					font-weight: 700;
					color: var(--white);
					font-family: "PeachiBold", sans-serif;
					font-style: normal;
					font-size: em(28);
				}

				p {
					color: var(--white);
					font-size: em(14);
				}

				&:before {
					content: "";
					width: 50%;
					height: 100%;
					float: left;
					shape-outside: polygon(
						0 0,
						98% 0,
						50% 6%,
						23.4% 17.3%,
						6% 32.6%,
						0 50%,
						6% 65.6%,
						23.4% 82.7%,
						50% 94%,
						98% 100%,
						0 100%
					);
					shape-margin: 7%;
				}

				span {
					&:before {
						content: "";
						width: 50%;
						height: 100%;
						float: right;
						shape-outside: polygon(
							2% 0%,
							100% 0%,
							100% 100%,
							2% 100%,
							50% 94%,
							76.6% 82.7%,
							94% 65.6%,
							100% 50%,
							94% 32.6%,
							76.6% 17.3%,
							50% 6%
						);
						shape-margin: 6%;
					}
				}
			}

			&:hover,
			&.ativo {
				.circle-text {
					// opacity: 1;
				}
			}
			.circle-wrapper {
				transform-style: preserve-3d;
				transition: 1s;
			}
			&:hover {
				.circle-wrapper {
					transform: rotateY(180deg);
					transition: transform 0.5s;
				}
			}

			.circle-plus {
				position: absolute;
				bottom: -25px;
				left: calc(50% - 30px);
				z-index: 99;
				width: 58px;
				height: 58px;
				background-repeat: no-repeat;
				display: flex;
				align-items: center;
				justify-content: center;

				@include mq(md) {
					display: none;
				}
			}

			.circle-icon {
				position: absolute;
				top: -40px;
				left: calc(50% - 40px);
				z-index: 99;
				width: 80px;
				height: 80px;
				background-repeat: no-repeat;
			}

			&#humana .circle-icon {
				background-image: url("data:image/svg+xml,%3Csvg id='Grupo_4137' data-name='Grupo 4137' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='80' height='79.998' viewBox='0 0 80 79.998'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Retângulo_789' data-name='Retângulo 789' width='80' height='80' fill='%23134a9c'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Grupo_4083' data-name='Grupo 4083' clip-path='url(%23clip-path)'%3E%3Cpath id='Caminho_15632' data-name='Caminho 15632' d='M68.285,11.715A39.885,39.885,0,0,0,40,0H0V40A40,40,0,1,0,68.285,11.715M66.5,40A26.5,26.5,0,0,1,13.51,40h6.3a20.2,20.2,0,0,0,40.152,3.149H55.6v-6.3H66.5Z' transform='translate(0 0)' fill='%23134a9c'/%3E%3C/g%3E%3C/svg%3E%0A");
			}

			&#tecnologica .circle-icon {
				background-image: url("data:image/svg+xml,%3Csvg id='Grupo_4136' data-name='Grupo 4136' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='80' height='80' viewBox='0 0 80 80'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Retângulo_790' data-name='Retângulo 790' width='80' height='80' fill='%23134a9c'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Grupo_4085' data-name='Grupo 4085' clip-path='url(%23clip-path)'%3E%3Cpath id='Caminho_15633' data-name='Caminho 15633' d='M40,0h0a40,40,0,1,0,0,80H80V40A40,40,0,0,0,40,0m4.311,13.388a27.648,27.648,0,0,1,13.171,5.453l1.056.809-4.809,4.813-.806-.584a20.672,20.672,0,0,0-8.947-3.7l-.982-.156,0-6.8ZM24.487,18.309A27.8,27.8,0,0,1,36.857,13.4l1.322-.179,0,6.806-.979.16A20.8,20.8,0,0,0,29,23.412l-.795.528-4.835-4.83ZM13.14,36.628a27.762,27.762,0,0,1,5.908-13.215l.815-1,4.8,4.791-.636.813a21.066,21.066,0,0,0-4.089,9.007l-.18.948-6.825,0Zm54.942,7.482A27.81,27.81,0,0,1,20.955,59.456a27.547,27.547,0,0,1-7.816-15.323l-.21-1.346,6.83,0,.179.95a21.048,21.048,0,0,0,41.352-.021l.18-.948,6.825,0Zm-6.618-6.157-.18-.95a20.684,20.684,0,0,0-3.579-8.326l-.581-.8,4.813-4.818.81,1.06A27.682,27.682,0,0,1,68.084,36.6l.21,1.346Z' fill='%23134a9c'/%3E%3C/g%3E%3C/svg%3E%0A");
			}

			&#sustentavel .circle-icon {
				background-image: url("data:image/svg+xml,%3Csvg id='Grupo_4135' data-name='Grupo 4135' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='80' height='80' viewBox='0 0 80 80'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Retângulo_791' data-name='Retângulo 791' width='80' height='80' fill='%23134a9c'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Grupo_4087' data-name='Grupo 4087' clip-path='url(%23clip-path)'%3E%3Cpath id='Caminho_15634' data-name='Caminho 15634' d='M233.363,132.658l-24.791-8.445-8.561,8.525,31.984,12.9h0a40.044,40.044,0,0,0,1.368-10.4Z' transform='translate(-153.363 -95.243)' fill='%23134a9c'/%3E%3Cpath id='Caminho_15635' data-name='Caminho 15635' d='M141.047,0h-2.9a40.061,40.061,0,0,0-10.145,1.3l12.957,32.117,8.563-8.527Z' transform='translate(-98.151)' fill='%23134a9c'/%3E%3Cpath id='Caminho_15636' data-name='Caminho 15636' d='M187.552,193.089h0l-34.467-13.9-8.648,8.612,35.336,17.122a40.041,40.041,0,0,0,7.779-11.829' transform='translate(-110.75 -137.393)' fill='%23134a9c'/%3E%3Cpath id='Caminho_15637' data-name='Caminho 15637' d='M236.833,0H209.275l6.981,20.492Z' transform='translate(-160.466 0)' fill='%23134a9c'/%3E%3Cpath id='Caminho_15638' data-name='Caminho 15638' d='M74.823,234.048,62.99,245.832l-3.862,3.846a40,40,0,0,0,50.762,1.362Z' transform='translate(-45.338 -179.461)' fill='%23134a9c'/%3E%3Cpath id='Caminho_15639' data-name='Caminho 15639' d='M25.651,100.66,8.571,65.41h0a40,40,0,0,0,1.259,51.006h0Z' transform='translate(0 -50.155)' fill='%23134a9c'/%3E%3Cpath id='Caminho_15640' data-name='Caminho 15640' d='M66.108,13.278h0A40.035,40.035,0,0,0,54.213,21L54.206,21l17.2,35.506,8.659-8.623Z' transform='translate(-41.564 -10.181)' fill='%23134a9c'/%3E%3Cpath id='Caminho_15641' data-name='Caminho 15641' d='M255.688,38.641l20.366,6.938V18.36Z' transform='translate(-196.054 -14.078)' fill='%23134a9c'/%3E%3C/g%3E%3C/svg%3E%0A");
			}
		}
	}

	.wrapper__large {
		@include mq(xs) {
			background-color: var(--silver);
			padding: 0px;
		}
	}

	&__crenca {
		@include mq(md) {
			display: flex;
			justify-content: space-between;
		}

		&-text {
			width: 100%;

			@include mq(md) {
				width: 45%;
			}

			h3,
			h2 {
				color: var(--dark-blue);
			}
			h3 {
				text-transform: uppercase;
			}
		}

		&-video {
			width: calc(100vw - 65px);
			height: calc(100vw - 65px);
			position: relative;
			margin-top: 50px;
			display: flex;
			align-items: center;
			justify-content: center;

			@include mq(md) {
				// width: 45%;
				width: 680px;
				height: 680px;
				margin-left: 170px;
			}

			a {
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 10;
			}
			img {
				width: calc(100vw - 65px);
				height: calc(100vw - 65px);

				position: relative;
				z-index: 10;
				border-radius: 600px;

				object-fit: cover;
				// margin-left: 170px;
				cursor: pointer;

				@include mq(md) {
					width: 680px;
					height: 680px;
				}
			}

			&:after {
				content: "";
				display: block;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='83.4' height='83.4' viewBox='0 0 83.4 83.4'%3E%3Cg id='Icon' transform='translate(0.866 0.867)'%3E%3Ccircle id='_36510546-bc8f-406f-bba5-a26874649ed8' data-name='36510546-bc8f-406f-bba5-a26874649ed8' cx='40.5' cy='40.5' r='40.5' transform='translate(0.334 0.333)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.4'/%3E%3Cpath id='e1eebb0e-e61c-4d7e-9993-174d585d206f' d='M12,9.6,36.5,25.933,12,42.267Z' transform='translate(20.667 14.9)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
				width: 81px;
				height: 81px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				// top: -360px;
				// left: 270px;
				position: absolute;
				cursor: pointer;
				z-index: 10;
				// top: calc(-50% - 40px);
				// left: calc(50% - 40px);
				// top: calc(50% - 40px);
				// left: calc(50% - 40px);
				top: 0;
				left: 0;
				// width: calc(100vw - 65px);
				// height: calc(100vw - 65px);
				background-color: rgba(0, 0, 0, 0.3);
				border-radius: 50%;
				background-size: 81px;
				width: 100%;
				height: 100%;
			}

			&::before {
				content: "";
				width: 110%;
				height: 110%;
				position: absolute;
				top: -4.5%;
				left: -4.5%;
				z-index: 7;
				background-image: url("../../images/el-curva-azul.png");
				background-position: top left;
				background-repeat: no-repeat;
				background-size: contain;
				transition: all 0.5s linear;
			}

			&:hover {
				&::before {
					transform: rotate(30deg);
					-webkit-transform: rotate(30deg);
					-moz-transform: rotate(30deg);
				}
			}
		}
	}

	&__gray {
		background-color: var(--silver);
		border-radius: 0px;
		margin: 80px auto;
		// padding-top: 40px !important;
		// padding-bottom: 40px !important;
		color: var(--dark-blue);
		padding: 0 !important;

		@include mq(md) {
			border-radius: 40px;
			padding: 40px 0 !important;
		}

		@include mq(xs) {
			padding: 40px 30px !important;
		}
	}

	&__cadeia {
		@include mq(md) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 50px;
		}

		&-text {
			width: 100%;

			@include mq(md) {
				width: 35%;
			}

			h3,
			h2 {
				color: var(--dark-blue);
			}
			h3 {
				text-transform: uppercase;
				font-size: em(16);
			}
			h2 {
				font-size: em(35);
			}
		}

		&-desk {
			display: none;

			h4 {
				font-weight: 700;
				color: var(--dark-blue);
				font-family: "PeachiBold", sans-serif;
				font-style: normal;
				font-size: em(28);
				width: 150px;
				position: absolute;
				text-align: center;
				top: 166px;
				left: calc(50% - 60px);
			}

			@include mq(md) {
				width: 55%;
				position: relative;
				display: block;
			}
		}

				&-mobile {
					display: block;
		
					@include mq(md) {
						display: none;
					}
		
					.cadeia__swiper {
						width: 280px;
						display: flex;
						flex-direction: row;
						overflow: hidden;
					}
		
					
		
					h4 {
						font-weight: 700;
						color: var(--dark-blue);
						font-family: "PeachiBold", sans-serif;
						font-style: normal;
						font-size: em(35);
						padding-bottom: 30px;
					}
		
					.swiper-wrapper {
						display: flex;
						flex-direction: column;
						height: 300px;
					}
		
					.swiper-slide {
						transition: all 0.3s ease;
						display: flex;
						justify-content: left;
						align-items: center;
						opacity: 0.3;
		
						img {
							max-width: 100%;
						}
		
						p {
							padding: 0 15px 0 10px;
							color: var(--dark-blue);
							font-size: 1.6em;
						}
					}
		
					.swiper-slide-active {
						opacity: 1;
					}
		
					.swiper-slide-prev,
					.swiper-slide-next {
						opacity: 0.3;
		
						img {
							max-width: 28%;
						}
		
						p {
							font-size: 1.4em;
							max-width: 150px;
						}
					}
		
					.swiper-group {
						// width: 100%;
						display: flex;
						flex-direction: column;
						flex-wrap: nowrap;
						align-items: center;
						justify-content: center;
						grid-gap: 10px;
						padding-bottom: 30px;
		
						.swiper-button-next,
						.swiper-button-prev {
							position: relative;
							margin: 0;
							top: 0;
							margin-top: 0;
							left: 0;
							right: 0;
							transition: transform 0.6s ease-in-out;
						}
		
						.swiper-button-next,
						.swiper-button-prev {
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cg id='Componente_80_2' data-name='Componente 80 – 2' transform='translate(0 50) rotate(-90)'%3E%3Ccircle id='Elipse_181' data-name='Elipse 181' cx='25' cy='25' r='25' fill='%23134a9c' opacity='0.2'/%3E%3Cg id='Icon' transform='translate(20 19)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cline id='c018084a-acf8-426b-9467-11b92e471d11' x1='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3Cpath id='_5f56acfb-6dcf-48d2-bce4-faecc2862052' data-name='5f56acfb-6dcf-48d2-bce4-faecc2862052' d='M10.286,16.286l-6-6,6-6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
							flex: 0 0 50px;

							&:hover{
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cg id='Componente_80_2' data-name='Componente 80 – 2' transform='translate(0 50) rotate(-90)'%3E%3Ccircle id='Elipse_181' data-name='Elipse 181' cx='25' cy='25' r='25' fill='%23134a9c'/%3E%3Cg id='Icon' transform='translate(20 19)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cline id='c018084a-acf8-426b-9467-11b92e471d11' x1='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3Cpath id='_5f56acfb-6dcf-48d2-bce4-faecc2862052' data-name='5f56acfb-6dcf-48d2-bce4-faecc2862052' d='M10.286,16.286l-6-6,6-6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
							}
						}
						.swiper-button-prev {						
							transform: scaleY(-1);
						}
						.swiper-button-next:active, .swiper-button-next:focus,
						.swiper-button-prev:active, .swiper-button-prev:focus{
							outline: none;
							box-shadow: none 						}						
					}
				}
	}

	&__diferenciais {
		margin-bottom: 80px;

		&-box {
			position: relative;
			padding-bottom: 70px;
			min-height: 400px;

			@include mq(md) {
				// overflow: hidden;
			}

			img {
				position: relative;
				border-radius: 50%;
				margin-bottom: 20px;
				z-index: 5;
			}
			h3 {
				color: var(--white);
				font-family: "PeachiBold", sans-serif;
				font-style: normal;
				font-size: em(30);
			}
			p {
				color: var(--white);
			}

			&::after {
				content: "";
				width: 360px;
				height: 360px;
				display: block;
				position: absolute;
				background-repeat: no-repeat;
				background-size: contain;
				z-index: 1;
			}

			.box {
				width: 100%;
				padding: 20px 30px;
				position: relative;
				z-index: 10;

				@include mq(md) {
					width: 462px;
					position: absolute;
				}
			}

			&.box-frangos,
			&:first-child {
				@include mq(md) {
					img {
						float: left;
					}
				}

				.box {
					background-color: var(--orange);
					border-radius: 0 30px 30px 30px;

					@include mq(md) {
						top: 175px;
						left: 275px;
					}
				}

				&::after {
					top: -50px;
					left: 30px;
					background-image: url("../../images/el-sobre-nos-orange.svg");

					@include mq(md) {
						top: 60px;
						left: 115px;
					}
				}
			}

			&.box-peixes,
			&:nth-child(2) {
				@include mq(md) {
					img {
						float: right;
					}
				}

				.box {
					background-color: var(--light-blue);
					border-radius: 30px 0 30px 30px;

					@include mq(md) {
						top: 175px;
						right: 275px;
					}
				}

				&::after {
					top: -50px;
					right: 30px;
					background-image: url("../../images/el-sobre-nos-teal.svg");

					@include mq(md) {
						top: 60px;
						right: 115px;
					}
				}
			}

			&.box-termo,
			&:last-child {
				@include mq(md) {
					img {
						float: left;
					}
				}

				.box {
					background-color: var(--dark-blue);
					border-radius: 0 30px 30px 30px;

					@include mq(md) {
						top: 175px;
						left: 275px;
					}
				}

				&::after {
					top: -50px;
					left: 30px;
					background-image: url("../../images/el-sobre-nos-blue.svg");

					@include mq(md) {
						top: 60px;
						left: 115px;
					}
				}
			}
		}
	}

	&__foto {
		@include mq(md) {
			padding-top: 30px;
			padding-bottom: 30px;

			img {
				border-radius: 30px;
			}
		}
	}

	&__responsabilidade {
		padding-top: 50px;
		padding-bottom: 50px;

		@include mq(md) {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px 50px;
			margin: 0 50px;
			position: relative;
		}

		&-image {
			width: 352px;
			position: relative;
			display: block;
			margin: auto;
			margin-bottom: 40px;

			@include mq(md) {
				margin-left: auto;
			}

			img {
				border-radius: 50%;
			}

			&::after {
				content: "";
				top: -10px;
				left: -10px;
				width: 372px;
				height: 372px;
				position: absolute;
				background-image: url("../../images/sobre-nos-curva-teal.png");
				background-position: top left;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		&-text {
			width: 100%;
			text-align: center;

			@include mq(md) {
				width: 45%;
				text-align: left;
			}

			h3,
			h2 {
				color: var(--dark-blue);
			}
			h3 {
				text-transform: uppercase;
				font-size: em(16);
			}
			h2 {
				font-size: em(35);
			}
		}
	}

	&__tagline {
		padding-top: 50px;
		padding-bottom: 50px;
		text-align: center;
	}
}