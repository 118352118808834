.novidades {
		padding-bottom: 100px;
		
		h2 { 
			font-size: em(16); 
			text-transform: uppercase; 
			text-align: center;
		}
		
		h3 { 
			font-size: em(30); 
			font-weight: bold; 
			color: var(--dark-blue); 
			text-align: center;
		}
		
		&__btn { 
			text-align: center;
			
			a { display: inline-block; }
		}
		
		&__cards {
			padding-top: 20px;
			padding-bottom: 20px;
			display: flex;
			gap: 5px;
			align-items: stretch;
			flex-direction: column;
			
			@include mq(md) {
				flex-direction: row;
			}
		}
		
		&__card {
			height: 370px;
			position: relative;
			border-radius: 30px;
			overflow: hidden;
			width: 100%;
			img { 
				width: 100%; 
				max-width: 100%;
				top: 0;
				position: absolute;
				height: 100%;
    			object-fit: cover;
			}
				
			@include mq(md) {
				width: 25%;
				// img { width: 125%; max-width: 125%; }
			}

			figure {
				margin: 0;
			}
		
			&-faixa {
				height: 206px;
				padding: 50px 15% 0 25px;
				width: 100%;
				position: absolute;
				background-size: cover;
				background-position: right;
				bottom: 0;
				background-image: url("data:image/svg+xml,%3Csvg id='Componente_144_1' data-name='Componente 144 – 1' xmlns='http://www.w3.org/2000/svg' width='539.313' height='206.8' viewBox='0 0 539.313 206.8'%3E%3Cpath id='Caminho_13170' data-name='Caminho 13170' d='M539.132,174.669s-214.185-18.2-329.322-8.113S0,206.8,0,206.8V0H539.313Z' transform='translate(539.313 206.8) rotate(180)' fill='%23002747'/%3E%3C/svg%3E");
				
				h4 {
					font-size: em(14); 
					color: var(--white);
					text-transform: uppercase;
				}
				
				p {
					font-size: em(20); 
					color: var(--white);
					font-family: "Outfit", sans-serif;
					font-weight: 300;
				}
			}

			&.card-1 {
				
			}
			
			&:first-child {
				width: 100%;
				// img { width: 100%; max-width: 100%; }
				
				@include mq(md) {
					width: 50%;
					// img { width: 100%; max-width: 100%;}
				}
			}
		}
		
		// .card-1 {
		// 	width: 100%;
		// 	img { width: 100%; max-width: 100%; }
			
		// 	@include mq(md) {
		// 		width: 25%;
		// 		img { width: 125%; max-width: 125%; }
		// 	}
		// }
		
		// .card-2 {
		// 	width: 100%;
		// 	img { width: 100%; max-width: 100%; }
			
		// 	@include mq(md) {
		// 		width: 50%;
		// 		img { width: 100%; max-width: 100%;}
		// 	}
		// }
	}