.contato-form {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px 60px;

	input, select, textarea {
		background: transparent;
		border-radius: 8;
		border: 1px solid var(--dark-blue);
	}

	label {
		color: var(--dark-blue);
	}

	select {
		appearance: none;
		background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Crect id='Area' width='12' height='12' transform='translate(0)' fill='none' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(0)'%3E%3Cpath id='be395840-9477-496e-9761-88ee6aa17a40' d='M0,0H12V12H0Z' fill='none'/%3E%3Cpath id='_96098602-5feb-46a6-b5ac-2fe8a5269d34' data-name='96098602-5feb-46a6-b5ac-2fe8a5269d34' d='M3,4.5l3,3,3-3' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
		background-position: calc(100% - 10px) 50%;
		background-repeat: no-repeat;
	}

	&__group {
		grid-column: span 2;

		&--small {
			grid-column: span 2;

			@include mq(md) { 
				grid-column: span 1;
			}
		}
	}

	.wpcf7-submit {
		border: 0;
		cursor: pointer;
    	padding: 25px 50px;
    	background-color: var(--dark-blue) !important;
    	border-radius: 50px;
    	color: #fff;
    	display: flex;
    	align-items: center;
    	justify-content: center;
	}
}