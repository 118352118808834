.banner {
	position: relative;
	overflow: visible;
	height: 90vh;
	margin-bottom: 180px;

	@include mq(md) {
		margin-bottom: 80px;
	}

	&-el {
		z-index: 2;
		&--esq {
			position: absolute;
			width: 45%;
			left: -75px;
			bottom: 6px;
			display: none;

			@include mq(md) {
				width: 334px;
				left: -75px;
				bottom: -67px;
				display: block;
			}
		}

		&--dir {
			position: absolute;
			width: 25%;
			right: 0;
			bottom: -95px;

			@include mq(md) {
				width: 222px;
				right: -75px;
				bottom: -195px;
			}
		}
	}

	.swiper-slide {
		position: relative;
	}

	&__prod {
		// width: 330px;
		// height: 330px;
		// position: absolute;
		// bottom: 0;

		@include mq(md) {
			position: relative;
			bottom: auto;
		}

		img {
			width: 90%;
			height: 100%;
			object-fit: contain;
			@include mq(md) {
				width: 100%;
			}
		}
	}

	&__img {
		width: 100vw;
		height: calc(90vh - 60px);
		position: static;

		&:after {
			display: block;
			content: "";
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1366.457' height='28.403'%3E%3Cpath data-name='Caminho 13190' d='M1366.457 0S982.799 28.4 684.396 28.4-.004 0-.004 0v28.4h1366.457Z' fill='%23fff'/%3E%3C/svg%3E");
			background-size: cover;
			background-position: top center;
			width: 100vw;
			height: 2vw; /*usando unidade de largura (vw) para manter a proporção*/
			position: absolute;
			z-index: 1;
			bottom: 59px;

			@include mq(md) {
				bottom: 0;
			}
		}

		&--mobile {
			display: flex;

			@include mq(md) {
				display: none;
			}
		}

		&--desktop {
			display: none;

			@include mq(md) {
				display: flex;
			}
		}

		img {
			// max-width: 170%;
			// width: 170%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: calc(90vh - 60px);
			width: 100vw;
			object-fit: cover;

			@include mq(md) {
				max-width: 100%;
				width: 100%;
				height: 90vh;
			}
		}
	}

	&__swiper {
		overflow: visible;
		height: 90vh;
	}

	&__txt {
		position: absolute;
		right: auto;
		bottom: auto;
		width: 100%;
		z-index: 10;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		text-align: center;
		height: 100%;
		justify-content: flex-end;
		top: 80px;

		@include mq(md) {
			right: 150px;
			bottom: -65px;
			width: 385px;
			height: auto;
			top: auto;
		}

		h2 {
			color: var(--white);
			text-shadow: 0 0 10px black;
			text-transform: uppercase;
			@include mq(md) {
				order: 1;
			}
		}

		h3 {
			color: var(--white);
			text-shadow: 0 0 10px black;
			@include mq(md) {
				order: 2;
			}
		}

		img {
			position: relative;
		}

		.banner__prod {
			@include mq(md) {
				order: 4;
			}
		}

		.banner__btn {
			@include mq(md) {
				order: 3;
				margin: 20px 0;
			}
		}
	}

	&__btn {
		display: inline-block;
		text-align: center;
		// position: absolute;
		// bottom: -40px;

		@include mq(md) {
			position: relative;
			bottom: auto;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		width: 50px;
		height: 50px;
		margin-top: -22px;
		z-index: 10;
		background-size: 50px 50px;
	}

	.swiper-pagination {
		padding: 10px;
		border-radius: 30px;
		background: #d4ccc5;
		position: absolute;
		left: 50%;
		margin-left: -30px;
		margin-top: -15px;
		text-align: center;
		z-index: 10;
	}

	.swiper-button-prev {
		left: 20px;
		transform: scaleX(-1);
		background-image: url("data:image/svg+xml,%3Csvg id='Componente_131_2' data-name='Componente 131 – 2' xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cg id='Elipse_181' data-name='Elipse 181' transform='translate(50 50) rotate(180)' fill='none' stroke='%23fff' stroke-width='1'%3E%3Ccircle cx='25' cy='25' r='25' stroke='none'/%3E%3Ccircle cx='25' cy='25' r='24.5' fill='none'/%3E%3C/g%3E%3Cg id='Icon' transform='translate(31 31) rotate(180)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cline id='c018084a-acf8-426b-9467-11b92e471d11' x1='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3Cpath id='_5f56acfb-6dcf-48d2-bce4-faecc2862052' data-name='5f56acfb-6dcf-48d2-bce4-faecc2862052' d='M10.286,16.286l-6-6,6-6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3C/g%3E%3C/g%3E%3Cg id='Elipse_227' data-name='Elipse 227' transform='translate(50 50) rotate(180)' fill='none' stroke='%23fff' stroke-width='1' opacity='0.5'%3E%3Ccircle cx='25' cy='25' r='25' stroke='none'/%3E%3Ccircle cx='25' cy='25' r='24.5' fill='none'/%3E%3C/g%3E%3C/svg%3E");
	}

	.swiper-button-next {
		right: 20px;
		background-image: url("data:image/svg+xml,%3Csvg id='Componente_131_2' data-name='Componente 131 – 2' xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cg id='Elipse_181' data-name='Elipse 181' transform='translate(50 50) rotate(180)' fill='none' stroke='%23fff' stroke-width='1'%3E%3Ccircle cx='25' cy='25' r='25' stroke='none'/%3E%3Ccircle cx='25' cy='25' r='24.5' fill='none'/%3E%3C/g%3E%3Cg id='Icon' transform='translate(31 31) rotate(180)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cline id='c018084a-acf8-426b-9467-11b92e471d11' x1='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3Cpath id='_5f56acfb-6dcf-48d2-bce4-faecc2862052' data-name='5f56acfb-6dcf-48d2-bce4-faecc2862052' d='M10.286,16.286l-6-6,6-6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3C/g%3E%3C/g%3E%3Cg id='Elipse_227' data-name='Elipse 227' transform='translate(50 50) rotate(180)' fill='none' stroke='%23fff' stroke-width='1' opacity='0.5'%3E%3Ccircle cx='25' cy='25' r='25' stroke='none'/%3E%3Ccircle cx='25' cy='25' r='24.5' fill='none'/%3E%3C/g%3E%3C/svg%3E");
	}
}

.home-content {
	overflow: hidden;
}
