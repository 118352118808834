.destaque-receitas {
	//position: relative;
	// padding-bottom: 160px;
	margin-top: -7%;
	margin-bottom: -10%;
	background: url(../../images/img-deco-left.png);
	background-repeat: no-repeat;
	background-position: left top;
	background-size: 80px;

	@include mq(md) {
		background-position: left 100px;
		padding-bottom: 0px;
		background-size: auto;
	}

	&__swiper {
		// overflow: hidden;
		overflow: visible;
		position: relative;
		display: flex;
		align-items: center;
	}

	.wrapper {
		// @include mq(md) {
		// 	position: relative;
		// }
	}

	.swiper-slide {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		opacity: 0;
		transition-duration: 0.2s;
		transition-delay: 0.2s;
		height: calc(100vw + 400px);

		@include mq(md) {
			flex-direction: row;
			height: 875px;
		}

		&-active {
			opacity: 1;
			transition-duration: 0.2s;
			transition-delay: 0s;
		}
	}

	&__img {
		padding-right: 0px;
		background-image: url(../../images/el-receitas-m.png);
		background-repeat: no-repeat;
		// padding: 11% 8% 11% 16%;
		position: relative;
		width: 100vw;
		height: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
		background-position: center;
		background-size: contain !important;

		@include mq(md) {
			background-image: url(../../images/bg-receitas-destaque.svg);
			// padding: 97px 48px 96px 135px;
			order: 2;
			// width: 580px;
			// width: 680px;
			position: absolute;
			// height: 680px;
			// width: 893px;
			// height: 893px;
			width: 960px;
			height: 878px;
			// border-radius: 50%;
			right: -450px;
		}

		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			background-image: url(../../images/bg-receitas-destaque-circle.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			display: block;
			transition: 0.4s ease-in-out;
		}

		.single-produto & {
			background-image: url(../../images/el-receitas-prod.svg);
		}

		.single-produto.term-frangos & {
			background-image: url(../../images/el-receitas-prod--aves.svg);

			&:before {
				background-image: url(../../images/bg-receitas-destaque-circle-aves.svg);
			}
		}

		.single-produto.term-peixes & {
			background-image: url(../../images/el-receitas-prod--peixes.svg);

			&:before {
				background-image: url(../../images/bg-receitas-destaque-circle-peixes.svg);
			}
		}

		.single-produto.term-embutidos & {
			background-image: url(../../images/el-receitas-prod--embutidos.svg);

			&:before {
				background-image: url(../../images/bg-receitas-destaque-circle-embutidos.svg);
			}
		}

		.single-produto.term-empanados & {
			background-image: url(../../images/el-receitas-prod--empanados.svg);

			&:before {
				background-image: url(../../images/bg-receitas-destaque-circle-empanados.svg);
			}
		}

		&:hover {
			&:before {
				transform: rotate(-19deg);
				transition: 0.4s ease-in-out;
			}
		}

		img {
			width: 70vw;
			height: 70vw;
			border-radius: 50%;
			object-fit: cover;

			@include mq(md) {
				width: 680px;
				height: 680px;
			}
		}
	}

	&__txt {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;

		h2 {
			font-size: em(16);
			text-transform: uppercase;
		}
		h3 {
			font-size: em(26);
			color: var(--dark-blue);
			font-weight: 700;
			text-align: center;

			span {
				color: var(--orange);
			}
		}

		a {
			text-decoration: none;
		}
	}

	@include mq(md) {
		&__txt {
			width: 480px;
			align-items: baseline;

			h2 {
			}
			h3 {
				font-size: em(36);
				text-align: left;
			}
		}
	}

	.swiper-group {
		position: absolute;
		bottom: 90px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		grid-gap: 30px;
		width: 100%;
		justify-content: center;
		right: 0;

		@include mq(md) {
			// right: 200px;
			bottom: auto;
			transform: rotate(90deg);
			z-index: 10;
			right: -80px;
			// right: -170px;
		}

		.swiper-button-next,
		.swiper-button-prev,
		.swiper-pagination {
			position: relative;
			margin: 0;
		}

		.swiper-button-next,
		.swiper-button-prev {
			margin: 0;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cg id='Componente_80_31' data-name='Componente 80 – 31' transform='translate(50 50) rotate(180)'%3E%3Ccircle id='Elipse_181' data-name='Elipse 181' cx='25' cy='25' r='25' fill='%23134a9c' opacity='0.2'/%3E%3Cg id='Icon' transform='translate(20 19)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cline id='c018084a-acf8-426b-9467-11b92e471d11' x1='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3Cpath id='_5f56acfb-6dcf-48d2-bce4-faecc2862052' data-name='5f56acfb-6dcf-48d2-bce4-faecc2862052' d='M10.286,16.286l-6-6,6-6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
			flex: 0 0 50px;
		}

		.swiper-button-prev {
			transform: scaleX(-1);
		}

		.swiper-pagination {
			background: transparent;
			display: flex;

			.swiper-pagination-bullet {
				width: 20px;
				height: 10px;
				border-radius: 10px;
				background: #ccd8e8;
				opacity: 1;
			}

			.swiper-pagination-bullet-active {
				opacity: 1;
				width: 50px;
				background: var(--dark-blue);
			}
		}
	}
}

.destaque-receitas:has(+ .footer) {
	margin-bottom: 0;
}
