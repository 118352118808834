.busca-vazia-content {
	background-color: var(--dark-blue);
	padding-top: 40px;
	padding-bottom: 80px;

	h1 {
		color: var(--white);
		text-align: center;
	}

	p {
		color: var(--white);
		text-align: center;
		padding: 20px 0;
	}

	&__btn {
		display: flex;
		justify-content: center;
		padding-top: 30px;

		a {
			&:hover {
				background: var(--text-blue);
			}
		}
	}

	&__filtro {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-bottom: 50px;
		padding-top: 40px;

		&-input {
			position: relative;

			input {
				cursor: pointer;
				width: 290px;
				background-color: var(--dark-blue);
				color: var(--white);
				border: 1px solid var(--white);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 20px;
				border-radius: 60px;
				height: 60px;
				margin: 0;
				padding-left: 20px;
				font-size: em(15);

				&::placeholder {
					opacity: 0.8;
					color: var(--white);
				}
			}

			#searchsubmit {
				cursor: pointer;
				z-index: 1;
				background-color: var(--text-blue);
				background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='bf85fc15-e260-4dc6-ab9f-b3885d5dddf6' d='M0,0H20V20H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_929e80f7-f3f8-48f4-83e4-d1ff3a788f4d' data-name='929e80f7-f3f8-48f4-83e4-d1ff3a788f4d' d='M14.167,8.333A5.833,5.833,0,1,1,8.333,2.5,5.834,5.834,0,0,1,14.167,8.333Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='b92afa92-b399-4075-9b76-418d49c58009' d='M17.5,17.5l-5-5' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3C/g%3E%3C/svg%3E%0A");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 20px;
				border: 0;
				border-radius: 50px;
				width: 50px;
				height: 50px;
				position: absolute;
				right: 5px;
				top: 5px;
				font-size: 0;
			}
		}
	}
}

.busca-content {
	padding-top: 50px;
	background-color: var(--silver);

	@include mq(md) {
		padding-top: 100px;
		padding-bottom: 50px;
	}

	h2 {
		color: var(--dark-blue);
		text-align: center;
		margin-bottom: 30px;

		@include mq(md) {
			text-align: left;
		}

		.busca-produtos & {
			margin-bottom: 0px;
			padding-bottom: 0px;

			@include mq(md) {
				margin-bottom: 30px;
				padding-bottom: 20px;
			}
		}
	}

	&__swiper {
		overflow: hidden;

		.swiper-slide {
			height: auto;
			display: flex;
			justify-content: center;
		}
	}

	&__filtro {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 50px;

		form {
			width: 100%;
		}

		ul {
			display: flex !important;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			padding-bottom: 50px;
			width: 100%;

			li:first-child {
				width: 100%;
			}
		}

		&-input {
			position: relative;
			display: none;

			@include mq(md) {
				display: block;
			}

			input {
				cursor: pointer;
				width: 412px;
				background-color: var(--silver);
				color: var(--dark-blue);
				border: 1px solid var(--dark-blue);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 20px;
				border-radius: 60px;
				height: 60px;
				margin: 0;
				padding-left: 20px;
				font-size: em(15);

				&::placeholder {
					opacity: 0.8;
					color: var(--dark-blue);
				}
			}

			button,
			#searchsubmit {
				cursor: pointer;
				z-index: 1;
				background-color: var(--dark-blue);
				background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='bf85fc15-e260-4dc6-ab9f-b3885d5dddf6' d='M0,0H20V20H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_929e80f7-f3f8-48f4-83e4-d1ff3a788f4d' data-name='929e80f7-f3f8-48f4-83e4-d1ff3a788f4d' d='M14.167,8.333A5.833,5.833,0,1,1,8.333,2.5,5.834,5.834,0,0,1,14.167,8.333Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='b92afa92-b399-4075-9b76-418d49c58009' d='M17.5,17.5l-5-5' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3C/g%3E%3C/svg%3E%0A");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 20px;
				border: 0;
				border-radius: 50px;
				width: 50px;
				height: 50px;
				position: absolute;
				right: 5px;
				top: 5px;
				font-size: 0;
			}
		}
		&-select {
			display: flex;
			flex-direction: row;

			a {
				margin: 0 10px;
				padding: 8px 30px;
				color: var(--dark-blue);
				border-color: var(--dark-blue);

				&:hover,
				&:active,
				&.ativo {
					color: var(--white);
					background-color: var(--dark-blue);
				}
			}
		}

		label {
			background: transparent;
			color: var(--dark-blue);
			border: 1px solid var(--dark-blue);
			width: 130px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 20px;
			cursor: pointer;

			input[type="checkbox"] {
				display: none;
			}

			&:has(input:checked) {
				background: var(--dark-blue);
				color: #fff;
			}
		}

		input[type="submit"] {
			background: var(--dark-blue);
			color: #fff;
			width: 130px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 20px;
		}
	}

	&__bloco {
		position: relative;

		hr {
			margin: 100px 0 50px;
			border: none;
			height: 20px;
			width: 100%;
			background-repeat: repeat-x;
			background-position: center;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='502' height='3' viewBox='0 0 502 3'%3E%3Cline id='Linha_50' data-name='Linha 50' x1='502.5' transform='translate(1.5 1.5)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-width='3' stroke-dasharray='0 10'/%3E%3C/svg%3E%0A");

			@include mq(md) {
				margin: 50px 0;
			}
		}

		.swiper-group {
			@include mq(xs) {
				position: absolute;
				bottom: 60px;
				right: 50%;
				margin-right: -50px;
				width: 100px;
			}

			@include mq(md) {
				position: absolute;
				top: 20px;
				right: 0;
				width: 100px;
			}

			.swiper-button-next,
			.swiper-button-prev {
				width: 30px;
				height: 30px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cg id='Grupo_4347' data-name='Grupo 4347' transform='translate(-1193 -518)'%3E%3Cg id='Icon' transform='translate(1198 534) rotate(-90)'%3E%3Crect id='Area' width='12' height='12' fill='none' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='be395840-9477-496e-9761-88ee6aa17a40' d='M0,0H12V12H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_96098602-5feb-46a6-b5ac-2fe8a5269d34' data-name='96098602-5feb-46a6-b5ac-2fe8a5269d34' d='M3,4.5l3,3,3-3' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3Cg id='Elipse_229' data-name='Elipse 229' transform='translate(1193 518)' fill='none' stroke='%23134a9c' stroke-width='1'%3E%3Ccircle cx='10.5' cy='10.5' r='10' stroke='none'/%3E%3Ccircle cx='10.5' cy='10.5' r='10' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
			}

			.swiper-button-prev {
				transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
			}
		}
	}

	.busca-receitas {
		&__cards {
			padding-top: 20px;
			padding-bottom: 20px;
			display: flex;
			row-gap: 50px;
			column-gap: 10px;
			align-items: stretch;
			flex-direction: column;
			flex-wrap: wrap;

			@include mq(md) {
				flex-direction: row;
			}
		}

		&__card {
			height: 370px;
			position: relative;
			border-radius: 30px;
			overflow: hidden;

			img {
				object-fit: cover;
				position: absolute;
				top: auto;
			}

			&-faixa {
				height: 206px;
				padding: 50px 15% 0 25px;
				width: 100%;
				position: absolute;
				background-size: cover;
				background-position: right;
				bottom: 0;
				background-image: url("data:image/svg+xml,%3Csvg id='Componente_144_1' data-name='Componente 144 – 1' xmlns='http://www.w3.org/2000/svg' width='539.313' height='206.8' viewBox='0 0 539.313 206.8'%3E%3Cpath id='Caminho_13170' data-name='Caminho 13170' d='M539.132,174.669s-214.185-18.2-329.322-8.113S0,206.8,0,206.8V0H539.313Z' transform='translate(539.313 206.8) rotate(180)' fill='white'/%3E%3C/svg%3E");

				h4 {
					color: var(--dark-blue);
					font-family: "PeachiBold", sans-serif;
					font-style: normal;
				}

				p {
					color: var(--dark-blue);
					font-family: "Outfit", sans-serif;
					font-weight: 300;
				}

				.timer {
					position: absolute;
					top: 20px;
					right: 15px;
					color: var(--dark-blue);
					font-family: "Outfit", sans-serif;
					font-weight: 300;
					padding-left: 28px;
					font-size: em(14);
					background-repeat: no-repeat;
					background-position: left 7px;
				}
			}

			&-tamanho-1 {
				width: 100%;

				h4 {
					font-size: em(20);
				}
				p {
					font-size: em(16);
				}

				@include mq(md) {
					img {
						width: 100%;
						max-width: 100%;
					}
				}
			}

			&-frangos {
				.busca-receitas__card-faixa {
					border-bottom: 10px solid var(--orange);
				}
				.timer {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ff9e00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ff9e00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ff9e00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ff9e00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
				}
			}

			&-peixes {
				.busca-receitas__card-faixa {
					border-bottom: 10px solid var(--light-blue);
				}
				.timer {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
				}
			}

			&-embutidos {
				.busca-receitas__card-faixa {
					border-bottom: 10px solid var(--brown);
				}
				.timer {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bd58a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bd58a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bd58a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bd58a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
				}
			}

			&-empanados {
				.busca-receitas__card-faixa {
					border-bottom: 10px solid var(--gold);
				}
				.timer {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bf364a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bf364a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bf364a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bf364a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
				}
			}
		}
	}

	.busca-produtos {
		h2 {
			padding-bottom: 20px;
		}

		&__item {
			display: flex;
			justify-content: space-evenly;

			@include mq(xs) {
				margin: 20px 0;
			}

			a {
				text-decoration: none;
			}

			h4 {
				text-align: center;
				color: var(--dark-blue);
				font-size: em(18);
				font-family: "PeachiBold", sans-serif;
				font-style: normal;
			}
		}
	}
}

.busca-novidades {
	h2 {
		padding-bottom: 20px;
	}

	hr {
		margin: 100px 0 0px;
		border: none;
		height: 20px;
		width: 100%;
		background: none;

		@include mq(md) {
			margin: 50px 0;
		}
	}

	&__cards {
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		gap: 5px;
		align-items: stretch;
		flex-direction: column;

		@include mq(md) {
			flex-direction: row;
		}
	}

	&__card {
		height: 370px;
		display: block;
		position: relative;
		border-radius: 30px;
		overflow: hidden;
		width: 100%;

		@include mq(xs) {
			margin: 20px 0;
		}

		img {
			width: 100%;
			max-width: 100%;
			top: 0;
			position: absolute;
			height: 100%;
			object-fit: cover;
		}

		figure {
			margin: 0;
		}

		&-faixa {
			height: 206px;
			padding: 50px 15% 0 25px;
			width: 100%;
			position: absolute;
			background-size: cover;
			background-position: right;
			bottom: 0;
			background-image: url("data:image/svg+xml,%3Csvg id='Componente_144_1' data-name='Componente 144 – 1' xmlns='http://www.w3.org/2000/svg' width='539.313' height='206.8' viewBox='0 0 539.313 206.8'%3E%3Cpath id='Caminho_13170' data-name='Caminho 13170' d='M539.132,174.669s-214.185-18.2-329.322-8.113S0,206.8,0,206.8V0H539.313Z' transform='translate(539.313 206.8) rotate(180)' fill='%23002747'/%3E%3C/svg%3E");

			h4 {
				font-size: em(14);
				color: var(--white);
				text-transform: uppercase;
			}

			p {
				font-size: em(20);
				color: var(--white);
				font-family: "Outfit", sans-serif;
				font-weight: 300;
			}
		}
	}
}
