@import "common/mixins";
@import "common/reset";
@import "components/header";
@import "components/nav";
@import "components/footer";
@import "components/modal";
@import "components/a11y";
@import "components/forms";
@import "components/swiper";
@import "components/hamburger";
@import "components/busca";
@import "components/aba-cookies";
@import "components/card-produto";
@import "components/receitas-card";
@import "common/colors";
@import "common/scaffolding";
@import "common/global";
@import "common/highcontrast";

//BLOCOS
@import "blocks/banner";
@import "blocks/certificados";
@import "blocks/destaque-produtos";
@import "blocks/destaque-receitas";
@import "blocks/dicas";
@import "blocks/novidades";

//GERAL PÁGINAS INTERNAS
@import "pages/page/heading";
@import "pages/page/content";

//ONDE ENCONTRAR
@import "pages/onde-comprar/heading";
@import "pages/onde-comprar/content";
@import "pages/onde-comprar/busca";

//PRODUTOS
@import "pages/produtos/heading";
@import "pages/produtos/content";
@import "pages/produtos/filtros";

//PRODUTO INTERNA
@import "pages/produto/heading";
@import "pages/produto/content";
@import "pages/produto/gallery";
@import "pages/produto/relacionados";

//RECEITAS
@import "pages/receitas/heading";
@import "pages/receitas/content";
@import "pages/receitas/avaliacoes";

//POLITICAS
@import "pages/politicas/heading";
@import "pages/politicas/content";

//SOBRE NOS
@import "pages/sobre-nos/heading";
@import "pages/sobre-nos/content-bkp";

//FALE CONOSCO
@import "pages/fale-conosco/heading";
@import "pages/fale-conosco/content";
@import "pages/fale-conosco/form";

//BUSCA
@import "pages/busca/heading";
@import "pages/busca/content";

// NOVIDADES
@import "pages/novidades/heading";
@import "pages/novidades/content";

// BUSCA RECEITAS
@import "pages/perguntas-receitas/heading";
@import "pages/perguntas-receitas/content";

//HOME
// @import "pages/home/content";

html {
	@include mq(sm) {
		color: inherit;
	}
	@include mq(md) {
		color: inherit;
	}
	@include mq(lg) {
		color: inherit;
	}
}
