.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(#000000, 0.8);
	cursor: pointer;
	display: none;
	transition: all 0.15s ease-in;
	z-index: 112;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	.bg-modal {
	}

	&-content {
		width: 80%;
		margin: 50px auto;
		position: relative;
		background: #fff;
		box-shadow: 0 0 20px rgba(#000000, 0.5);
		border-radius: 25px;
		padding: 50px 25px;
		max-width: 800px;
		cursor: default;

		@include mq(md) {
			padding: 50px 100px;
		}
	}

	&-close {
		position: absolute;
		right: 40px;
		top: 30px;
		color: var(--text-blue);
		border: 0;
		background: transparent;
		cursor: pointer;
		display: flex;
		grid-gap: 10px;
		align-items: center;

		&:before {
			content: "";
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg id='Icon' transform='translate(0.5 0.5)'%3E%3Crect id='Area' width='16' height='16' fill='%23003168' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Ccircle id='fa5f5fe8-fd0f-4d90-a39e-de494ab8b9d1' cx='8' cy='8' r='8' transform='translate(-1.29 -2.499)' fill='%23003168' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='_8beb2922-058e-4960-a691-69541ab0a224' data-name='8beb2922-058e-4960-a691-69541ab0a224' d='M9.6,6.4,6.4,9.6l3.2,3.2' transform='translate(-2.89 -4.099)' fill='%23003168' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='_7a4f4de6-09d0-4aac-9f6f-6400826a1a59' data-name='7a4f4de6-09d0-4aac-9f6f-6400826a1a59' x1='6.4' transform='translate(3.51 5.501)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
			width: 16px;
			height: 16px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
	}

	&-univirtus {
		&__bts {
			display: flex;
			justify-content: center;
			grid-gap: 20px;
			margin: 20px 0;
		}

		.bg-modal {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		.modal-content {
			width: 70%;
		}
	}
}

.modal.is--visible {
	visibility: visible;
	opacity: 1;
	display: block;

	// .bg-modal {
	//   display: flex;
	// }
}

.bt-modal-processos {
	cursor: pointer;
}
