:root {
	--cor-categoria: var(--dark-blue);
}

.term-frangos {
	--cor-categoria: var(--orange);
}

.term-peixes {
	--cor-categoria: var(--light-blue);
}

.produto-heading {
	color: #fff;
	background-color: var(--cor-categoria);
	padding-bottom: 250px;
	padding-top: 100px;
	position: relative;
	z-index: 1;

	// background-image: url("../../images/el-heading.png");
	// 	background-position: 0 -150px;

	@include mq(md) {
		// 	background-image: url("../../images/el-heading-m.png"),
		// url("../../images/el-heading.png");
		// 	background-position: calc(100% - 50px) calc(100% + 200px), 0 -150px;
		padding-bottom: 60px;
		padding-top: 115px;
	}

	// &--frangos {
	// 	background-color: var(--orange);
	// }

	&__grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 20px;
		align-items: center;

		@include mq(md) {
			grid-template-columns: 7fr 5fr;
		}
	}

	&__txt {
		position: relative;
	}

	&__claims {
		text-align: center;
		margin: 30px -30px 0 -30px;
		display: flex;
		overflow: scroll;
		width: 100vw;
		padding: 0 30px 20px 30px;

		@include mq(md) {
			width: auto;
			padding: 0;
			margin: 30px 0 0 0;
			overflow: visible;
		}

		li {
			border-left: 1px solid #fff;
			width: 105px;
			padding: 5px;
			flex: 0 0 105px;
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: em(14);

			&:first-child {
				border-left: 0;
			}

			img {
				width: 50px;
				height: 40px;
				object-fit: contain;
				object-position: center;
				margin-bottom: 10px;
			}
		}

		// 	.produto-heading__arrow {
		// 	display: block;

		// 	background-repeat: no-repeat;
		// 	background-position: center;

		// 	@include mq(md) {
		// 		display: none;
		// 	}
		// }

		&.has--scroll {
			padding: 0 50px 20px 50px;

			@include mq(md) {
				padding: 0;
			}
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.715' height='14.424' viewBox='0 0 13.715 14.424'%3E%3Cg id='Icon' transform='translate(0.857 1.212)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cline id='c018084a-acf8-426b-9467-11b92e471d11' x1='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3Cpath id='_5f56acfb-6dcf-48d2-bce4-faecc2862052' data-name='5f56acfb-6dcf-48d2-bce4-faecc2862052' d='M10.286,16.286l-6-6,6-6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"),
					linear-gradient(
						270deg,
						rgba(0, 0, 0, 0) 0%,
						var(--cor-categoria) 30%
					);
				content: "";
				display: block;
				width: 50px;
				flex: 0 0 50px;
				background-repeat: no-repeat;
				background-position: 10px center, center;
				position: absolute;
				height: 150px;
				left: -30px;
				bottom: 0;

				@include mq(md) {
					display: none;
				}
			}

			&:after {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.715' height='14.424' viewBox='0 0 13.715 14.424'%3E%3Cg id='Icon' transform='translate(12.857 13.212) rotate(180)'%3E%3Crect id='Area' width='12' height='12' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cline id='c018084a-acf8-426b-9467-11b92e471d11' x1='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3Cpath id='_5f56acfb-6dcf-48d2-bce4-faecc2862052' data-name='5f56acfb-6dcf-48d2-bce4-faecc2862052' d='M10.286,16.286l-6-6,6-6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.714'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"),
					linear-gradient(
						90deg,
						rgba(0, 0, 0, 0) 0%,
						var(--cor-categoria) 30%
					);
				content: "";
				display: block;
				width: 50px;
				flex: 0 0 50px;
				background-repeat: no-repeat;
				background-position: calc(100% - 10px) center, center;
				position: absolute;
				right: -30px;
				height: 150px;
				bottom: 0px;

				@include mq(md) {
					display: none;
				}
			}
		}
	}
}
