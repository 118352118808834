.produto-content {
	padding-top: 50px;
	margin-bottom: 100px;
	z-index: 2;
	position: relative;

	@include mq(md) {
		padding-top: 100px;
	}

	&__grid {
		display: flex;
		grid-gap: 20px;
		flex-direction: column-reverse;

		@include mq(md) {
			display: grid;
			grid-template-columns: 7fr 5fr;
		}
	}
	&__descricao{
		padding-bottom: 30px;
	}
	&__accordeon {
		padding: 5px 0;
		color: var(--dark-blue);

		h2 {
			display: flex;
			align-items: center;
			position: relative;
			font-size: em(14);

			font-family: "PeachiRegular", sans-serif;
			text-transform: uppercase;
			font-weight: 400;
			cursor: pointer;

			&:after /* estilo para o botão de +*/ {
				display: block;
				right: 0px;
				position: absolute;
				background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Crect id='Area' width='12' height='12' fill='none' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='be395840-9477-496e-9761-88ee6aa17a40' d='M0,0H12V12H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_96098602-5feb-46a6-b5ac-2fe8a5269d34' data-name='96098602-5feb-46a6-b5ac-2fe8a5269d34' d='M3,4.5l3,3,3-3' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
				width: 12px;
				height: 12px;
				content: "";
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		&:after {
			background-image: linear-gradient(
				to right,
				var(--dark-blue) 30%,
				transparent 0%
			);
			content: "";
			background-size: 10px 3px;
			width: 100%;
			height: 3px;
			margin-top: 20px;
			display: block;
		}
	}

	&__infos {
		@include mq(md) {
			padding-right: 70px;
		}
	}

	&__detalhes {
		padding-bottom: 10px;
		overflow: hidden;
		padding-top: 20px;

		table {
			width: 100%;
			border-collapse: collapse;
		}

		&--info-com {
			table {
				tr td:nth-child(2) {
					text-align: right;
				}

				tr:nth-child(2n) {
					background: #e8ebf5;
				}
			}
		}

		&--info-nut {
			table {
				td {
					border-bottom: 1px solid #e8ebf5;
				}
			}
		}
	}

	details summary {
		list-style-type: none;
	}

	details summary::-webkit-details-marker {
		display: none;
	}
	summary::-webkit-details-marker /*oculta a setinha que vem por padrão*/ {
		display: none;
	}

	summary:focus /*tira o foco do accordeon*/ {
		outline: none;
	}

	&__descricao {
		text-align: center;

		@include mq(md) {
			text-align: left;
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;

		@include mq(md) {
			flex-direction: row;
		}

		.btn {
			height: 70px;
		}
	}
	&__social {
		border: 1px solid var(--dark-blue);
		border-radius: 50px;
		align-items: center;
		margin-top: 20px;
		padding: 15px 20px;
		font-size: 1.6em;
		font-weight: 500;
		text-decoration: none;
		transition: all 0.2s;
		display: flex;
		color: var(--dark-blue);
		grid-gap: 10px;
		max-width: 100%;
		height: 70px;

		@include mq(md) {
			padding: 15px 30px;
		}

		ul {
			display: flex;
		}

		&__ico {
			width: 35px;
			height: 35px;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
			background-color: transparent;
			border: 0;
			cursor: pointer;

			&--copy {
				background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='f446ec93-7a44-44f2-a4d0-4c0d193f68b1' d='M0,0H20V20H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_132d46c8-e222-4a06-93bd-7348688fd553' data-name='132d46c8-e222-4a06-93bd-7348688fd553' d='M8.333,6.667H15a1.667,1.667,0,0,1,1.667,1.666V15A1.668,1.668,0,0,1,15,16.667H8.333A1.667,1.667,0,0,1,6.667,15V8.333A1.666,1.666,0,0,1,8.333,6.667Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='_6429a736-384b-4b26-8e58-42580a03593e' data-name='6429a736-384b-4b26-8e58-42580a03593e' d='M13.333,6.667V5a1.667,1.667,0,0,0-1.666-1.667H5A1.668,1.668,0,0,0,3.333,5v6.667A1.667,1.667,0,0,0,5,13.333H6.667' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3C/g%3E%3C/svg%3E%0A");
			}

			&--fb {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.167' height='15' viewBox='0 0 9.167 15'%3E%3Cg id='Icon' transform='translate(-5.833 -2.5)'%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_3fd5e289-fb06-46ea-a800-3aa8acd7f3e8' data-name='3fd5e289-fb06-46ea-a800-3aa8acd7f3e8' d='M5.833,8.333v3.334h2.5V17.5h3.334V11.667h2.5L15,8.333H11.667V6.667a.833.833,0,0,1,.833-.834H15V2.5H12.5A4.167,4.167,0,0,0,8.333,6.667V8.333h-2.5' transform='translate(-1.29 -2.499)' fill='%23134a9c'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
			}

			&--ig {
				background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='c8efa91b-40b1-497b-b2da-d9a50c4eb278' d='M0,0H20V20H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_9b29878b-8a86-4313-98c6-07bb5ae4420f' data-name='9b29878b-8a86-4313-98c6-07bb5ae4420f' d='M6.667,3.333h6.666a3.334,3.334,0,0,1,3.334,3.334v6.666a3.334,3.334,0,0,1-3.334,3.334H6.667a3.334,3.334,0,0,1-3.334-3.334V6.667A3.334,3.334,0,0,1,6.667,3.333Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='ad3fe467-d36f-41de-80c5-b0f23d82f4c7' d='M12.5,10A2.5,2.5,0,1,1,10,7.5,2.5,2.5,0,0,1,12.5,10Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='_0fd52e91-dce8-488b-95b2-2aca34a9804f' data-name='0fd52e91-dce8-488b-95b2-2aca34a9804f' d='M13.75,6.25h0' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3C/g%3E%3C/svg%3E%0A");
			}

			&--wp {
				background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='cec45f6d-5030-4217-b71d-88e1fbeb171d' d='M0,0H20V20H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='c12cf40d-d8c4-4c92-bfec-9b094223b676' d='M2.5,17.5l1.375-3.167A7.5,7.5,0,1,1,6.708,16.75L2.5,17.5' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='_6daa3510-2fe2-4cad-ab47-2203e96305e9' data-name='6daa3510-2fe2-4cad-ab47-2203e96305e9' d='M7.5,8.333a.417.417,0,1,0,.833,0V7.5a.417.417,0,1,0-.833,0v.833A4.166,4.166,0,0,0,11.667,12.5H12.5a.417.417,0,1,0,0-.833h-.833a.417.417,0,1,0,0,.833' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3C/g%3E%3C/svg%3E%0A");
			}
		}
	}

	&__copiar {
		input {
			display: none;
		}

		.is--copied {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.828' height='13.414' viewBox='0 0 18.828 13.414'%3E%3Cpath id='check-svgrepo-com' d='M4,12.611,8.923,17.5,20,6.5' transform='translate(-2.586 -5.086)' fill='none' stroke='%23134A9C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
		}
	}
}
