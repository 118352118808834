.contato-content {
	padding-top: 70px;
	padding-bottom: 100px;

	@include mq(md) { 
		padding-top: 100px;
		background-image: 	url("../../images/el-politicas.svg"),
							url("../../images/el-heading.png");
		background-position: -160px 500px, calc(100% + 160px) 500px;
		background-repeat: no-repeat, no-repeat;
	}
	
	&__grid {
		
		@include mq(md) { 
			display: flex;
			justify-content: space-between;
		}
	}
	&__container-form {
		width: 100%;
		
		@include mq(md) { width: 50%;}
	}
	&__container-dados {
		
		width: 100%;
		padding: 0;
		
		@include mq(md) { 
			width: 50%;
			padding: 30px 60px;
		}

		a {
			color: var(--dark-blue);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
		
		.box {
			border: 1px solid var(--dark-blue);
			border-radius: 30px;
			padding: 25px 40px;
			margin-bottom: 30px;
			
			h2 {
				color: var(--dark-blue);
			}
			
			ul {
				padding-top: 20px; 
				li {
					position: relative;
					padding-left: 25px;
					
					&::before {
						content: '';
						position: absolute;
						width: 17px;
						height: 15px;
						top: 2px;
						left: 0;
						background-repeat: no-repeat;
					}


					
					&.ico-fone {
						&::before {
							background-image: 	url("../../images/ico-fone.svg");
						}
					}
					
					&.ico-mail {
						&::before {
							top: 5px;
							background-image: 	url("../../images/ico-mail.svg");
						}
					}
				}
			}
		}
	}
}