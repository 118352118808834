.header {
	height: 80px;
	width: 100%;
	padding: 12px 0;
	margin-bottom: -90px;
	position: relative;
	z-index: 99999;
	top: 0;

	@include mq(md) {
		height: 90px;
	}

	.home & {
		background: linear-gradient(
			180deg,
			var(--dark-blue) 0%,
			rgba(0, 0, 0, 0) 100%
		);
	}

	.is--scrolling & {
		position: fixed;
		background: var(--dark-blue);

		&:after {
			content: "";
			height: 1.53vw;
			width: 100%;
			bottom: calc(-1.53vw + 1px);
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1366.457' height='21.596' viewBox='0 0 1366.457 21.596'%3E%3Cpath id='Banner_Desk' data-name='Banner Desk' d='M-5239.544,4309.7s-383.658,21.6-682.061,21.6-684.4-21.6-684.4-21.6h1366.457Z' transform='translate(6606.001 -4309.7)' fill='%23134a9c'/%3E%3C/svg%3E%0A");
			background-size: 100%;
			background-position: top;
			background-repeat: no-repeat;
			position: absolute;
		}
	}

	.hamburger {
		display: inline-block;
		z-index: 999;

		@include mq(md) {
			display: none;
		}
	}

	@include mq(md) {
		padding: 5px 0;
	}

	&__wrapper {
		// display: flex;
		// justify-content: space-between;
		grid-gap: 20px;
		align-items: center;

		display: flex;
		justify-content: space-between;

		@include mq(md) {
			display: grid;
			grid-template-columns: 140px 2fr 220px;
		}
	}

	&-aux {
		display: flex;
		align-items: center;
		grid-gap: 5px;
		justify-content: flex-end;
		position: relative;

		&__idiomas {
			position: relative;

			a {
				display: flex;
				align-items: center;
				font-size: 1.4em;
				border: 1px solid var(--dark-blue);
				background-color: var(--dark-blue);
				color: var(--white);
				border-radius: 16px;
				padding: 8px 15px;
				text-decoration: none;
				gap: 5px;
				width: 95px;
				@include mq(md) {
					padding: 8px 10px;
					width: unset;
				}

				&.principal {
					z-index: 2;
					pointer-events: none;
				}
			}

			.idiomas-dropdown {
				position: absolute;
				top: 100%;
				left: 0;
				display: none;
				flex-direction: column;
				grid-gap: 5px;
				z-index: 1;
				background: var(--dark-blue);
				padding: 10px 0;
				border-radius: 10px;
				box-shadow: 0px 20px 20px 5px #00000020;
				cursor: pointer;

				a {
					border: none;
					background-color: transparent;
					color: var(--white);
					border-radius: 16px;
					padding: 8px 20px 8px 15px;
					text-decoration: none;
					cursor: pointer;
					@include mq(md) {
						padding: 8px 20px 8px 15px;
					}
				}
			}

			&:hover .idiomas-dropdown {
				display: flex;
			}
		}

		&__favs {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.914' height='20' viewBox='0 0 21.914 20'%3E%3Cg id='Icon' transform='translate(0.957)' opacity='0.7'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='f4e742d1-d3dd-4682-92b4-06c189c8fdc6' d='M19.94,4.411a5.263,5.263,0,0,0-7.444,0L11.482,5.425,10.467,4.411a5.264,5.264,0,1,0-7.444,7.444l1.015,1.014,7.444,7.444,7.443-7.444,1.015-1.014A5.263,5.263,0,0,0,19.94,4.411Z' transform='translate(-2.772 -4.09)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.914'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
			width: 50px;
			height: 50px;
			background-size: 20px;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 50%;
			margin-right: 35px;

			@include mq(md) {
				margin-right: 70px;
			}
		}
	}
}

.has--menu {
	//oculta a rolagem quando o menu mobile está aberto
	overflow: hidden;
	height: 100vh;
}
