.aba-cookies {
	background: var(--text-blue);
	position: fixed;
	min-height: 90px;
	padding: 50px 30px;
	display: none;
	z-index: 9999999999;
	border-radius: 50px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	width: calc(100% - 20px);

	@include mq(md) {
		padding: 25px 80px;
		border-radius: 90px;
	}

	&.show {
		display: block;
	}

	&__wrapper {
	  display: grid;
	  align-items: center;
	  flex-direction: column;
	  grid-gap: 30px;
	  grid-template-columns: 1fr;

	  @include mq(md) {
	    grid-template-columns: repeat(2, 1fr);
	  }
	}

	&__btn {
		white-space: nowrap;
		background: var(--text-blue);
		color: #fff;
		border: 0;
		border-radius: 30px;
		font-size: em(14) !important;
		padding: 20px !important;
		cursor: pointer;
		margin: 0 !important;
		justify-self: flex-start;

		@include mq(md) {
			padding: 20px 85px !important;
			justify-self: flex-end;
		}

		&:hover {
			background: #093770 !important;
		}
	}

	p {
		color: #fff;
		margin: 0;
	}

	a {
		color: #fff;
		font-weight: 700;
	}
}
