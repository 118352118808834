form {
  select, input, textarea {
    margin-top: 5px;
    font-size: 1em;
    background: #1C5BA215;
    border: 1px solid #587CA815;
    border-radius: 50px;
    color: var(--dark-blue);
    resize: none;
    font-family: inherit;
    padding: 16px;
    width: 100%;
  }

  option {
    background: #FFFFFF;
    color: #00185B;
  }

  option[value="Contato"],
  option[value="Já sou cliente"] {
      color:#516088;
      font-size: .8em;
      pointer-events: none;
    }

  textarea {
    height: 200px;
  }

  .required {
    color: var(--light-blue);
  }

  .wpcf7-submit {
    background-color: transparent !important;
    color: #fff;
    border: 1px solid #186EFF50;
    padding: 18px 30px 18px 30px;
    font-size: em(10);
    font-weight: 500;
    margin: 0 auto;
    margin-top: 20px;
    display: table;
    align-items: center;
    transition: .2s;
    grid-gap: 60px;
    border-radius: 50px;
    text-decoration: none;
    padding: 18px 80px;
    width: auto;
    cursor: pointer;

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.5' height='14.121' viewBox='0 0 13.5 14.121'%3E%3Cg id='Icon' transform='translate(2.04 3.56)'%3E%3Cline id='_33f784a2-2078-419f-9690-41f501274191' data-name='33f784a2-2078-419f-9690-41f501274191' x2='12' transform='translate(-1.29 3.501)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='ad20c76c-e5b4-499c-b017-9e6d856e861e' d='M10.286,4.286l6,6-6,6' transform='translate(-5.576 -6.785)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");;
      width: 17px;
      height: 12px;
      display: block;
      background-size: contain;
      background-position: left;
      transition: .2s;
      background-repeat: no-repeat;
    }

    &:hover {
      transition: .2s;
      background-image: linear-gradient(180deg, var(--blue04) 0%, var(--light-blue) 100%);

      &:after {
        background-position: right;
        transition: .2s;
      }
    }
  }

  input[type=range] {
  height: 21px;
  -webkit-appearance: none;
  margin: 0px;
  width: 100%;
  border: 0;
  background: transparent;
  padding: 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #3071A9;
  border-radius: 0px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: var(--light-blue);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #3071A9;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #3071A9;
  border-radius: 0px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: var(--light-blue);
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #3071A9;
  border: 0px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #3071A9;
  border: 0px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: var(--light-blue);
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071A9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #3071A9;
}

.wpcf7-acceptance {
  .wpcf7-list-item {
    margin: 0;
  }
  label {
    display: flex;
    align-items: center;
  }

  input {
    width: 30px;
    margin-right: 5px;
    flex: 0 0 30px;
  }
}

}

.form-fields {
  display: block;

  a {
    color: #fff;
  }
}

.form-success {
  display: none;
}

.sent {
  .form-fields {
    display: none;
  }
  .form-success {
  display: block;
}
}

.wpcf7-response-output {
  font-size: em(14);
    border-radius: 20px;
    padding: 10px 15px;
}