.receitas-card {
	// height: 370px;
	position: relative;
	border-radius: 30px;
	overflow: hidden;
	width: 100%;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding-bottom: 15px;

	&:after {
		height: 10px;
		width: 100%;
		content: "";
		background: var(--dark-blue);
		display: block;
		position: absolute;
		bottom: 0;
	}

	h4 {
		font-size: em(20);
	}
	p {
		font-size: em(16);
	}

	@include mq(md) {
		width: 24%;
	}

	&__img {
		width: 100%;
		height: 210px;
		object-fit: cover;
		object-position: center;
		margin: 0;

		img {
			width: 100%;
			max-width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__faixa {
		// height: 206px;
		padding: 40px 25px 0 25px;
		width: 100%;
		// position: absolute;

		background-size: 101%;
		bottom: 0;
		background-position: top center;
		background-image: url("data:image/svg+xml,%3Csvg id='Componente_144_1' data-name='Componente 144 – 1' xmlns='http://www.w3.org/2000/svg' width='539.313' height='206.8' viewBox='0 0 539.313 206.8'%3E%3Cpath id='Caminho_13170' data-name='Caminho 13170' d='M539.132,174.669s-214.185-18.2-329.322-8.113S0,206.8,0,206.8V0H539.313Z' transform='translate(539.313 206.8) rotate(180)' fill='white'/%3E%3C/svg%3E");
		margin-top: -45px;
		position: relative;
		height: 100%;
		background-repeat: no-repeat;

		@include mq(md) {
			padding: 30px 25px 0 25px;
			// background-position: calc(100% - 1px) top;
		}

		h4 {
			color: var(--dark-blue);
			font-family: "PeachiBold", sans-serif;
			font-style: normal;
			padding-right: 30px;
			font-weight: 400;

			// @include mq(md) {
			// 	padding-right: 0;
			// }
		}

		p {
			color: var(--dark-blue);
			font-family: "Outfit", sans-serif;
			font-weight: 400;
		}

		.timer {
			// position: absolute;
			// right: 15px;
			color: var(--dark-blue);
			font-family: "Outfit", sans-serif;
			font-weight: 300;
			padding-left: 28px;
			font-size: em(14);
			background-repeat: no-repeat;
			background-position: left 7px;
			// height: 40px;
			display: flex;
			align-items: center;
			background-position: center left;
			// top: 40px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
			float: right;
			width: 40px;
			line-height: em(10);
			top: 0px;

			@include mq(md) {
				// top: 20px;
			}
		}
	}

	&__ttl {
		// display: flex;
		// align-items: flex-start;
	}

	&:first-child,
	&:nth-child(10n) {
		width: 100%;
		grid-column: span 2;

		img {
			/*top: -50%;*/
			width: 100%;
			max-width: 100%;
		}
		h4 {
			@include mq(md) {
				font-size: em(25);
			}
		}
		p {
			@include mq(md) {
				font-size: em(20);
			}
		}

		.receitas-card__faixa {
			background-position: right top;
			padding: 40px 25px 0 25px;

			@include mq(md) {
				padding: 50px 25px 0 25px;
			}
		}

		@include mq(md) {
			width: 50%;
			img {
				width: 100%;
				max-width: 100%;
			}
		}
	}

	&-frangos,
	&-poultry,
	&-aves {
		&:after {
			background: var(--orange);
		}

		.timer {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ff9e00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ff9e00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ff9e00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23ff9e00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
		}
	}

	&-peixes,
	&-fish,
	&-pescados {
		&:after {
			background: var(--light-blue);
		}

		.timer {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
		}
	}

	&-embutidos,
	&-cold-cuts,
	&-embutidos-es {
		&:after {
			background: var(--brown);
		}

		.timer {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bd58a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bd58a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bd58a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bd58a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
		}
	}

	&-empanados,
	&-breaded,
	&-empanados-es {
		&:after {
			background: var(--gold);
		}

		.timer {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='_7046f460-fa41-4257-93b1-b61b8beb2296' data-name='7046f460-fa41-4257-93b1-b61b8beb2296' d='M0,0H24V24H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_59b58ae7-6dfc-4839-9d7e-d664a9de4baa' data-name='59b58ae7-6dfc-4839-9d7e-d664a9de4baa' d='M19,13a7,7,0,1,1-7-7A7,7,0,0,1,19,13Z' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bf364a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_57efa451-0c62-4872-808c-01ec6558515b' data-name='57efa451-0c62-4872-808c-01ec6558515b' d='M12,10v3h2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bf364a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_9d2049d7-0d83-4936-80a7-cc72981eb6e8' data-name='9d2049d7-0d83-4936-80a7-cc72981eb6e8' d='M7,4,4.25,6' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bf364a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='_12f2d01f-0f52-4be0-87f7-e8d79c61040b' data-name='12f2d01f-0f52-4be0-87f7-e8d79c61040b' d='M17,4l2.75,2' transform='translate(-1.29 -2.499)' fill='none' stroke='%23bf364a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
		}
	}

	.busca-receitas & {
		width: 100%;
		height: 100%;

		@include mq(md) {
			width: 100%;
		}

		h4 {
			font-size: em(20);
		}

		p {
			font-size: em(16);
		}

		.receitas-card__faixa {
			padding: 40px 25px 0 25px;

			@include mq(md) {
				padding: 30px 25px 0 25px;
			}
		}
	}
}
