:root {
	--dark-blue: #134a9c;
	--light-blue: #3bbfc0;
	--text-blue: #002747;
	--orange: #ff9e00;
	--white: #f3f5f7;
	--purple: #bd58a1;
	--yellow: #f1dc53;
	--gray: #e8ebf5;
	--wine: #bf364a;
	--silver: #f3f5f7;
	--brown: #af6117;
	--gold: #e48100;
}

//cores do admin
.has-dark-blue-color {
	color: var(--dark-blue);
}
.has-light-blue-color {
	color: var(--light-blue);
}
.has-text-blue-color {
	color: var(--text-blue);
}
.has-orange-color {
	color: var(--orange);
}
.has-purple-color {
	color: var(--purple);
}
