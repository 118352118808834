.politicas-heading {

	@include mq(md) { 	
		background-image: 	url("../../images/el-heading-m.png"),
							url("../../images/el-heading.png");
		background-position: calc(100% - 50px) calc(100% + 200px), 0 -150px;
    }

	.heading__grid {
        grid-template-columns: none;
    }
	
}