.certificados {
    background: #F3F5F7;
    padding: 60px 0;
    text-align: center;

    &__list {
    	flex-wrap: wrap;
    	justify-content: center;
    	grid-gap: 15px;
    	margin-top: 40px;
    	display: flex;

    	li {
    		display: inline-flex;
    	}
    }

    &__btn {
    	border: 1px solid var(--dark-blue);
    	display: flex;
    	align-items: center;
    	padding: 10px 23px;
    	grid-gap: 15px;
    	border-radius: 50px;
    	color: var(--dark-blue);
    	text-decoration: none;
    	white-space: nowrap;
    	transition: .2s;

    	&:after {
    		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.811' height='19.811' viewBox='0 0 19.811 19.811'%3E%3Cpath id='Caminho_15593' data-name='Caminho 15593' d='M21,21l-5.2-5.2m0,0A7.5,7.5,0,1,0,5.2,15.8,7.5,7.5,0,0,0,15.8,15.8ZM10.5,7.5v6m3-3h-6' transform='translate(-2.249 -2.249)' fill='none' stroke='%23134a9c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' opacity='0.7'/%3E%3C/svg%3E%0A");
    		content: '';
    		display: block;
    		width: 18px;
    		height: 18px;
    		background-size: contain;
    		background-repeat: no-repeat;
    		background-position: center;
    	}

    	&:hover {
    		background: var(--dark-blue);
    		color: #fff;
    		transition: .2s;

    		&:after {
    			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.811' height='19.811' viewBox='0 0 19.811 19.811'%3E%3Cpath id='Caminho_15597' data-name='Caminho 15597' d='M21,21l-5.2-5.2m0,0A7.5,7.5,0,1,0,5.2,15.8,7.5,7.5,0,0,0,15.8,15.8ZM10.5,7.5v6m3-3h-6' transform='translate(-2.249 -2.249)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' opacity='0.7'/%3E%3C/svg%3E%0A");
    		}
    	}
    }
}