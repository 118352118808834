.onde-busca {
	border-radius: 30px;
	background: #fff;
	position: relative;
	z-index: 10;
	padding: 0;
	margin-top: 0px;
	display: flex;
	flex-direction: column;
	color: var(--dark-blue);
	grid-gap: 20px;

	@include mq(md) {
		// margin-top: -60px;
		padding: 20px;
	}

	&__field {
		display: flex;
		width: 100%;
		border: 1px solid var(--dark-blue);
		border-radius: 100px;

		button {
			font-size: 0;
			border: 0;
			background-color: var(--dark-blue);
			border-radius: 100px;
			height: 50px;
			width: 50px;
			cursor: pointer;
			flex: 0 0 50px;
			background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon'%3E%3Cpath id='bf85fc15-e260-4dc6-ab9f-b3885d5dddf6' d='M0,0H20V20H0Z' fill='none'/%3E%3Cpath id='_929e80f7-f3f8-48f4-83e4-d1ff3a788f4d' data-name='929e80f7-f3f8-48f4-83e4-d1ff3a788f4d' d='M14.167,8.333A5.833,5.833,0,1,1,8.333,2.5,5.834,5.834,0,0,1,14.167,8.333Z' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='b92afa92-b399-4075-9b76-418d49c58009' d='M17.5,17.5l-5-5' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3C/g%3E%3C/svg%3E%0A");
			background-position: center;
			background-repeat: no-repeat;
		}

		input {
			border: 0;
			width: 100%;
			background: transparent;
			height: 50px;
			padding: 15px 20px;
			font-size: em(16);
		}
	}

	p {
		text-align: center;
		font-size: em(18);
		margin-bottom: 0;
	}

	&__filtros {
		display: flex;
		justify-content: center;
	}

	&__filtro {
		font-size: em(18);
		border: 1px solid var(--dark-blue);
		background: #134a9c15;
		color: var(--dark-blue);
		padding: 7px 7px 7px 20px;
		display: flex;
		border-radius: 100px;
		grid-gap: 15px;
		line-height: em(13);

		&:after {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32.527' height='32.527' viewBox='0 0 32.527 32.527'%3E%3Cg id='Grupo_4005' data-name='Grupo 4005' transform='translate(-603.869 -28.991) rotate(-45)'%3E%3Ccircle id='Elipse_265' data-name='Elipse 265' cx='11.5' cy='11.5' r='11.5' transform='translate(395 459)' fill='%23134a9c'/%3E%3Cline id='Linha_67' data-name='Linha 67' x2='10.5' transform='translate(401 470.75)' fill='none' stroke='%23fff' stroke-width='2'/%3E%3Cline id='Linha_68' data-name='Linha 68' x2='10.5' transform='translate(406.25 465.5) rotate(90)' fill='none' stroke='%23fff' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
			width: 23px;
			height: 23px;
			content: "";
			cursor: pointer;
			display: block;
			background-position: center;
			flex: 0 0 23px;
		}
	}

	&__resultados {
		display: flex;
		flex-direction: column;
		grid-gap: 8px;
		max-height: 350px;
		overflow-y: scroll;
		padding-right: 10px;

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: var(--dark-blue);
		}

		&::-webkit-scrollbar {
			width: 7px;
			background-color: transparent;
			border: 3px solid white;
		}

		@include mq(md) {
			max-height: 560px;
		}
	}

	&__resultado {
		border-radius: 15px;
		background: var(--gray);
		color: var(--dark-blue);
		padding: 20px 25px;
		cursor: pointer;

		&.is--active {
			background: var(--dark-blue);
			color: #fff;
		}

		h3 {
			font-size: em(16);
			font-weight: 700;
			margin: 0;
		}

		p {
			font-size: em(16);
			text-align: left;
			margin: 0;
		}
	}

	&__rotas {
		font-size: em(16);
		display: flex;
		align-items: center;
		grid-gap: 10px;
		margin-top: 5px;
		svg path {
			fill: var(--dark-blue);

			.is--active & {
				fill: #fff;
			}
		}
	}
}
