.novidades-heading {
	padding-bottom: 50px;

	@include mq(md) {
		background-image: url("../../images/el-heading-m.png"),
			url("../../images/el-heading.png");
		background-position: calc(100% - 50px) calc(100% + 200px), 0 -150px;
	}

	p {
		color: white;
	}

	.heading__grid {
		grid-template-columns: none;
		grid-gap: 0;
	}

}

.novidades-heading-content {
	padding-bottom: 190px;
	background-position: -150px -190px;

	@include mq(md) {
		background-image: url("../../images/el-heading-white.png");
		background-position: 0 -150px;
		padding-bottom: 80px;
	}
}