.sobre-heading {
	background-image: url("../../images/el-heading.png");

	background-position: calc(50% - 60px) calc(100% + 100px), 0 -150px;

	background-size: 390px, auto;

	padding-bottom: 250px;

	@include mq(md) {
		background-image: url("../../images/el-heading.png"),
			url("../../images/el-heading-m.png");

		background-position: calc(100% - 290px) calc(100% + 100px), 0 -150px,
			calc(100% - 50px) calc(100% + 200px);

		background-size: 450px, auto, auto;
		padding-bottom: 60px;

		h1 {
			padding: 20px 0;
			width: 100%;

			@include mq(md) {
				width: 50%;
				padding: 40px 0;
			}
		}
	}

	.heading__grid {
		grid-template-columns: none;
	}
}
