.footer {
	position: relative;
	background-color: var(--dark-blue);
	color: #fff;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='709' height='800' viewBox='0 0 709 800'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Retângulo_2139' data-name='Retângulo 2139' width='709' height='800' transform='translate(328 2414)' fill='%23f3f5f7'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Grupo_de_máscara_172' data-name='Grupo de máscara 172' transform='translate(-328 -2414)' clip-path='url(%23clip-path)'%3E%3Cg id='Grupo_2810' data-name='Grupo 2810' transform='translate(9 -1788.132)'%3E%3Cg id='Grupo_2801' data-name='Grupo 2801' transform='translate(233.681 4224.681)' opacity='0.7'%3E%3Cg id='Elipse_68' data-name='Elipse 68' transform='translate(30.319 30.319)' fill='none' stroke='%23002747' stroke-width='1' opacity='0.4'%3E%3Ccircle cx='410' cy='410' r='410' stroke='none'/%3E%3Ccircle cx='410' cy='410' r='409.5' fill='none'/%3E%3C/g%3E%3Cg id='Elipse_69' data-name='Elipse 69' transform='translate(20.319 20.319)' fill='none' stroke='%23002747' stroke-width='1' opacity='0.3'%3E%3Ccircle cx='420' cy='420' r='420' stroke='none'/%3E%3Ccircle cx='420' cy='420' r='419.5' fill='none'/%3E%3C/g%3E%3Cg id='Elipse_70' data-name='Elipse 70' transform='translate(10.319 10.319)' fill='none' stroke='%23002747' stroke-width='1' opacity='0.2'%3E%3Ccircle cx='430' cy='430' r='430' stroke='none'/%3E%3Ccircle cx='430' cy='430' r='429.5' fill='none'/%3E%3C/g%3E%3Cg id='Elipse_71' data-name='Elipse 71' transform='translate(0.319 0.319)' fill='none' stroke='%23002747' stroke-width='1' opacity='0.1'%3E%3Ccircle cx='440' cy='440' r='440' stroke='none'/%3E%3Ccircle cx='440' cy='440' r='439.5' fill='none'/%3E%3C/g%3E%3C/g%3E%3Ccircle id='Elipse_81' data-name='Elipse 81' cx='400' cy='400' r='400' transform='translate(274 4265)' fill='%23002747'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	background-position-x: center;
	background-position-y: bottom;
	background-repeat: no-repeat;

	@include mq(md) {
		background-image: none;
	}

	&:before {
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="1366.457" height="36.334"><path data-name="Caminho 13204" d="M0-.001s383.658 30.51 682.061 30.51 684.4-30.51 684.4-30.51v36.334H0Z" fill="%23134a9c"/></svg>');
		content: '';
		background-size: cover;
		background-position: top center;
		width: 100vw;
		height: 3vw;
		/*usando unidade de largura (vw) para manter a proporção*/
		position: absolute;
		top: -2vw;
		z-index: 1;
		display: block;

		@include mq(md) {
			top: -2.5vw;
			height: 2.5vw;
		}
	}

	h2 {
		padding: 30px 5%;

		@include mq(md) {
			padding: 30px 17%;
		}
	}

	&__selo {
		display: none;

		@include mq(md) {
			display: block;
			position: absolute;
			bottom: 200px;
			left: 5%;
		}
	}

	&__instagram {
		padding: 40px 0 50px;
		text-align: center;

		.btn-icone {
			display: inline-block;

			span {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				vertical-align: bottom;
				display: inline-block;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='20' height='20' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z'%3E%3C/path%3E%3C/svg%3E");
			}
		}
	}

	&__grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		position: relative;
		min-height: 350px;
	}

	&__logo {
		padding: 60px 0 30px;
		order: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;

		img {
			width: 236px;
			height: 107px;

			&:nth-of-type(1) {
				margin-bottom: 50px;
			}
		}

		@include mq(md) {
			padding: 150px 0 0;
			order: -1;
			display: flex;
			flex-direction: row;
			position: relative;
			left: -10px;

			img {
				width: 177px;
				height: 91px;

				&:nth-of-type(1) {
					margin-right: 50px;
				}
			}
		}

	}

	&__links {
		display: flex;
		row-gap: 8px;
		column-gap: 15px;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;


		@include mq(md) {
			padding: 100px 130px 100px;
			width: 824px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='824' height='824' viewBox='0 0 880 880'%3E%3Cg id='Grupo_2811' data-name='Grupo 2811' transform='translate(-234 -4307)'%3E%3Cg id='Grupo_2810' data-name='Grupo 2810' transform='translate(0 82)'%3E%3Cg id='Grupo_2801' data-name='Grupo 2801' transform='translate(233.681 4224.681)' opacity='0.7'%3E%3Cg id='Elipse_68' data-name='Elipse 68' transform='translate(30.319 30.319)' fill='none' stroke='%23f3f5f7' stroke-width='1' opacity='0.4'%3E%3Ccircle cx='410' cy='410' r='410' stroke='none'/%3E%3Ccircle cx='410' cy='410' r='409.5' fill='none'/%3E%3C/g%3E%3Cg id='Elipse_69' data-name='Elipse 69' transform='translate(20.319 20.319)' fill='none' stroke='%23f3f5f7' stroke-width='1' opacity='0.3'%3E%3Ccircle cx='420' cy='420' r='420' stroke='none'/%3E%3Ccircle cx='420' cy='420' r='419.5' fill='none'/%3E%3C/g%3E%3Cg id='Elipse_70' data-name='Elipse 70' transform='translate(10.319 10.319)' fill='none' stroke='%23f3f5f7' stroke-width='1' opacity='0.2'%3E%3Ccircle cx='430' cy='430' r='430' stroke='none'/%3E%3Ccircle cx='430' cy='430' r='429.5' fill='none'/%3E%3C/g%3E%3Cg id='Elipse_71' data-name='Elipse 71' transform='translate(0.319 0.319)' fill='none' stroke='%23f3f5f7' stroke-width='1' opacity='0.1'%3E%3Ccircle cx='440' cy='440' r='440' stroke='none'/%3E%3Ccircle cx='440' cy='440' r='439.5' fill='none'/%3E%3C/g%3E%3C/g%3E%3Cpath id='Caminho_13203' data-name='Caminho 13203' d='M400,0C620.914,0,800,179.086,800,400S620.914,800,400,800,0,620.914,0,400,179.086,0,400,0Z' transform='translate(274 4265)' fill='%23002747'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		}

		.btn-border {
			align-self: auto;
			display: inline-block;
			margin-top: 15px;

			&.seta-coop:after {
				content: '';
				width: 20px;
				height: 20px;
				margin-left: 10px;
				vertical-align: bottom;
				display: inline-block !important;
				background-image: url("data:image/svg+xml,%3Csvg id='Icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Crect id='Area' width='20' height='20' fill='%23fcfcfc' opacity='0'/%3E%3Cg id='Icon-2' data-name='Icon' transform='translate(1.29 2.499)'%3E%3Cpath id='f7ec879f-d546-43ee-9423-55717a67c15f' d='M0,0H20V20H0Z' transform='translate(-1.29 -2.499)' fill='none'/%3E%3Cpath id='_83ced14b-d8e1-4ada-bee6-801de5653be3' data-name='83ced14b-d8e1-4ada-bee6-801de5653be3' d='M14.167,5.833,5.833,14.167' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3Cpath id='b076e9a6-5bb5-494d-8d06-682b8c759276' d='M6.667,5.833h7.5v7.5' transform='translate(-1.29 -2.499)' fill='none' stroke='%233bbfc0' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667'/%3E%3C/g%3E%3C/svg%3E");
			}

		}

		&-social {
			display: block !important;

			a {
				height: 20px;
				width: 20px;
				margin: 0 10px;
				display: inline-block;
				font-size: 0;



			}

			&-in {

				&:after {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='20' height='20' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z'%3E%3C/path%3E%3C/svg%3E");
					content: '';
					width: 20px;
					height: 20px;
					display: block;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}
	}

	&__legal {
		order: 3;
		text-align: center;
		padding: 30px 7% 60px;
		font-size: em(14);
		line-height: em(13);

		@include mq(md) {
			position: absolute;
			bottom: 35px;
			right: 0;
			width: 824px;
			padding: 0;
		}

		a {
			color: white;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}

			&:nth-child(3) {
				display: block;

				@include mq(md) {
					display: inline-block;
				}
			}
		}
	}
}

//AJUSTES INSTAGRAM 

.esf_insta_load_more_btns_wrap {
	display: none !important;
}

.esf_insta_feeds_holder.esf_insta_feeds_row .efbl-row-content .esf-insta-row-wrapper.esf-insta-story-wrapper {
	border: 0 !important;
	padding: 0 !important;
	border-radius: 20px !important;
	overflow: hidden !important;
	background: transparent !important;
}

.esf_insta_feed_wraper {
	width: 100vw !important;
	margin: 0 -30px !important;
	padding: 0;
	text-align: left;
	overflow: scroll;

	@include mq(md) {
		width: 100% !important;
		margin: 0 !important;
		overflow: auto;
	}
}

.esf_insta_feeds_holder.esf_insta_feeds_row {
	grid-gap: 15px !important;
	width: 1160px !important;
	padding: 0 30px 20px 30px;

	@include mq(md) {
		width: 100% !important;
		padding: 0;
	}
}

.esf_insta_feeds_holder.esf_insta_feeds_row .efbl-row-content {
	width: 260px !important;
	max-width: 100% !important;

	@include mq(md) {
		width: 100% !important;
	}
}